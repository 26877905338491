import { TypeEnvironment } from 'src/app/domain/enums/application/environment.enum'
import { Environment } from 'src/app/domain/interfaces/application/environment.interface'

export const environment: Environment = {
  production: true,
  default: 'production environment',
  type: TypeEnvironment.PRODUCTION,
  version: require('./version.json').productionVersion,

  auroraURL: 'https://aurora.linnks.com.br',

  apiURL: 'https://api.linnks.com.br',
  apiSocketURL: 'wss://api.linnks.com.br',
  pyAnaliticsURL: 'https://pyanalitics.octalink.com.br',
  socketURL: 'wss://pysocket.octalink.com.br',
  octaremoteURL: 'https://octaremote.octalink.com.br',

  datadogApplicationId: 'e141e66b-1e99-4364-9841-49e6c45f9a8e',
  datadogClientToken: 'pub779cce8b95fae240db506307852906c9'
}
