import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, inject } from '@angular/core'
import { ConfirmationService } from 'primeng/api'
import { ConfirmDialog as confirmDialogPrimeNG } from 'primeng/confirmdialog'
import { ConfirmDialogService } from 'src/app/data/services/components/confirm-dialog/confirm-dialog.service'
import { ButtonColor, ButtonVariant } from 'src/app/domain/enums/components/button.enum'
import { PrimeIcon } from 'src/app/domain/enums/components/prime-icons.enum'
import { ConfirmDialog } from 'src/app/domain/interfaces/components/confirm-dialog.interface'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  providers: [ ConfirmationService ]
})
export class ConfirmDialogComponent implements OnInit {

  private confirmationService: ConfirmationService = inject(ConfirmationService)
  private confirmDialogService: ConfirmDialogService = inject(ConfirmDialogService)

  @ViewChild('confirmDialog') confirmDialog: confirmDialogPrimeNG | any

  callbackAccept: Function = () => this.confirmDialog.accept()
  callbackReject: Function = () => this.confirmDialog.reject()

  buttonVariant = ButtonVariant
  buttonColor = ButtonColor
  primeIcon = PrimeIcon

  public ngOnInit(): void {
    this.confirmDialogService.confirmSubject.subscribe((confirmDialog: ConfirmDialog) => {
      this.showConfirm(confirmDialog)
    })
  }

  public showConfirm(confirmDialog: ConfirmDialog): void {
    this.confirmationService.confirm({ 
      message: confirmDialog.message,
      accept: () => { confirmDialog.acceptedCallback() },
      reject: () => { confirmDialog?.rejectedCallback ? confirmDialog?.rejectedCallback() : null },
    })

   this.resetWindowFocus()
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    if (this.confirmDialog) this.confirmDialog.accept()
  }

  private resetWindowFocus() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  
    window.focus()
  }
}
