import { Injectable } from '@angular/core'
import { datadogLogs } from '@datadog/browser-logs'
import { Subject } from 'rxjs'
import { SocketPulseAction, SocketPulseMethod, SocketPulseService } from 'src/app/domain/enums/application/socket.enum'
import { SocketPulse } from 'src/app/domain/interfaces/application/socket.interface'
import { environment } from 'src/environments/environment'

const SOCKET_URL = environment.socketURL

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  webSocket: WebSocket | null = null

  socketSubject: Subject<SocketPulse> = new Subject()

  private companyId: number = 0
  private userId: number = 0
  private userToken: string = ''

  public connect(companyId?: number, userId?: number, userToken?: string): void {
    this.disconnect()

    this.companyId = companyId ?? this.companyId
    this.userId = userId ?? this.userId
    this.userToken = userToken ?? this.userToken

    if(companyId !== 0) {
      this.webSocket = new WebSocket(`${SOCKET_URL}/${this.companyId}/${this.userId}/${true}`)
    
      this.createConnection(this.userToken)
      this.getPulses()
    }
  }

  public disconnect(): void {
    if (this.webSocket) this.webSocket.close()
    this.webSocket = null
  }

  public isConnected(): boolean {
    if (!this.webSocket) return false
    return ((this.webSocket.readyState == this.webSocket.CLOSED) || (this.webSocket.readyState == this.webSocket.CLOSING)) ? false : true
  }

  public async send(socketPulse: SocketPulse): Promise<void> {
    if (this.isConnected() && this.webSocket) {
      this.webSocket.send(JSON.stringify(socketPulse))
    } else datadogLogs.logger.error(`Falha de conexão com o socket (${this.companyId}-${this.userId}) (${JSON.stringify(socketPulse)})`)
  }

  private createConnection(userToken: string): void {
    if (this.webSocket) this.webSocket.onopen = () => {
      this.send({
        service: SocketPulseService.LOGIN,
        action: SocketPulseAction.AUTH,
        method: SocketPulseMethod.POST,
        content: { token: userToken }
      })
    }
  }

  private getPulses(): void {
    if (this.webSocket) this.webSocket.onmessage = (pulse: MessageEvent<any>) => {
      if(pulse.data.method === SocketPulseMethod.ERROR) throw new Error(String(pulse))
      const pulseJsonFormat = JSON.parse(pulse.data)
      this.socketSubject.next(pulseJsonFormat)
    }
  }
}
