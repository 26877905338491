import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

const API_URL = environment.apiURL

@Injectable({
  providedIn: 'root'
})
export class ServerHealthRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)
  
  public getAPIHealth(): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/proof/auth`, { observe: 'response', responseType: 'text'})
  }
}