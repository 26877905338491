import { Component, Input, OnInit, inject } from '@angular/core'
import { SplashScreenService } from 'src/app/data/services/components/splash-screen/splash-screen.service'
import { Theme } from 'src/app/domain/enums/application/theme.enum'
import { BrandingVariant } from 'src/app/domain/enums/components/branding.enum'
import { PrimeIcon } from 'src/app/domain/enums/components/prime-icons.enum'

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  private splashScreenService: SplashScreenService = inject(SplashScreenService)

  @Input() selectedTheme: Theme = Theme.DEFAULT

  isShowSplashScreen: boolean = false

  bradingVariant = BrandingVariant
  primeIcon = PrimeIcon
  theme = Theme

  public ngOnInit(): void {
    this.splashScreenService.splashSubject.subscribe((display: boolean) => {
      this.changeDisplaySplashScreen(display)
    })
  }

  private changeDisplaySplashScreen(display: boolean): void {
    this.isShowSplashScreen = display
  }
}
