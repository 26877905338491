import { Component, Input } from '@angular/core'
import { BrandingVariant } from 'src/app/domain/enums/components/branding.enum'

@Component({
  selector: 'app-offline-screen',
  templateUrl: './offline-screen.component.html',
  styleUrls: ['./offline-screen.component.scss']
})
export class OfflineScreenComponent {
  @Input() isShow: boolean = false

  octavioSadVersionImage: string = `assets/images/octavio/sad-version-2.png`

  brandingVariant = BrandingVariant
}
