import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { CodeInputModule as _CodeInputModule } from 'angular-code-input'

@NgModule({
  imports: [ 
    CommonModule,
    _CodeInputModule
  ],
  exports: [
    _CodeInputModule
  ]
})
export class CodeInputModule { }
