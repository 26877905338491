import { Component, Input } from '@angular/core'
import { Icon, IconColor, IconCursor, IconSize } from 'src/app/domain/enums/components/icon.enum'
import { TooltipEvent, TooltipPosition } from 'src/app/domain/enums/components/tooltip.enum'

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() icon: Icon = Icon.DEFAULT
  @Input() size: IconSize = IconSize.MEDIUM
  @Input() color: IconColor = IconColor.LIGHT
  @Input() cursor: IconCursor = IconCursor.POINTER
  @Input() tooltipLabel: string = ''
  @Input() tooltipPosition: TooltipPosition = TooltipPosition.BOTTOM
  @Input() tooltipEvent: TooltipEvent = TooltipEvent.HOVER

  iconAvaible = Icon

  public isChevronAlign(): boolean {
    return ['chevron-left', 'chevron-down'].includes(this.icon) && (this.size === 'small')
  }

}
