import { Injectable, inject } from '@angular/core'
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { UserService } from 'src/app/data/services/application/user/user.service'
import { HomeRoutes } from 'src/app/presentation/view/home/home.router'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  
  private userService: UserService = inject(UserService)
  private router: Router = inject(Router)

  public canLoad(route: Route, segments: UrlSegment[]): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.isLogged()) {
      this.router.navigate([HomeRoutes.URL])
      return false
    }

    return true
  }

}
