import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public toastSubject: Subject<Toast> = new Subject()

  public sendToast(toast: Toast): void {
    this.toastSubject.next(toast)
  }
}
