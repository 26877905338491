import { DatePipe } from '@angular/common'

// Date -> 2023-06-31
export function formatDateForSQL(period: Date): string {
  const date: Date = new Date(period)

  let dateFormat: string = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

  return dateFormat
}

export function formatDateForISO8601(period: Date | number): string {
  const date: Date = new Date(period)

  let dateFormat: string = `${date.getFullYear()}-${((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))}-${(date.getDate() > 9) ? (date.getDate()) : ('0' + date.getDate())}`

  return dateFormat
}

export function formatDateStringForDateCalendar(period: string | null): Date {
  if (period !== null) {
    let dateFragmentString: Array<string> = period.split('/'), dateFragmentNumber: Array<number> = new Array(), dateCalendar: Date = new Date()

    dateFragmentNumber = dateFragmentString.map((fragmentDate: string) => { return Number(fragmentDate) })
  
    dateCalendar = new Date(dateFragmentNumber[2], (dateFragmentNumber[1] - 1), dateFragmentNumber[0], 3)
  
    return dateCalendar
  } else return new Date()
}

export function formatDateForISO8601WithHours(period: Date | number, useTimeZoneOffSet: boolean = true): string {
  const dateFormat: string = formatDateForISO8601(period)
  const date: Date = new Date(period)

  // 2023-02-01 12:00:00-03:00

  return  `${dateFormat} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}${useTimeZoneOffSet ? `-${date.getTimezoneOffset()}:${date.getMilliseconds()}`: ''}`
}

/**
* Hoje às 19hr30 - Ontem às 19hr30 - 10/11/2022 às 19hr 30
**/
export function formatDateForWhatsappFormat(period: Date): string {
  const date = new Date(period)
  const today = new Date()

  const fullDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
  const fullToday = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
  const yesterday = (today.getDate() - 1) + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()

  if (fullDate === fullToday) return 'Hoje às ' + date.getHours() + 'hr' + (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes())
  else if (fullDate === yesterday) return 'Ontem às ' + date.getHours() + 'hr' + (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes())
  else return fullDate + ' às ' + date.getHours() + 'hr' + (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes())
}

export function formatDateForDatePipeString(period: Date | string, format: string = 'dd/MM/yyyy', timezone: string = '+003'): string {
  const datePipe: DatePipe = new DatePipe('pt-BR')

  let date: Date | string

  if (typeof period === 'string') date = new Date(period)
  else date = period
  
  let dateFormat: string = datePipe.transform(date, format, timezone) as string

  return dateFormat
}

// 2023-06-31 -> Date
export function formatDateSQLforDateCalendar(dateSQLFormat: string | null): Date {
  if (dateSQLFormat !== null) {
    let arrayDate: Array<number> = []

    arrayDate = dateSQLFormat.split('-').map(item => Number(item))
  
    return new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2])
  } return new Date()
}

export function formatTimestampforDateCalendar(timestamp: number | null, isIncludeTimezone: boolean = false): Date {
  if (timestamp !== null) {
    let date: Date = new Date(timestamp)

    if (isIncludeTimezone) date.setHours(date.getHours() + 3)
  
    return date
  } return new Date()
}

// Função que converte de 'yyyy-mm-dd' para 'dd/mm/yyyy'
export function formatDateSQLForDatePipeString(dateSQL: string): string {
  if (!dateSQL) return '';
  
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  
  if (!datePattern.test(dateSQL))
    throw new Error('Formato de data inválido. Use o formato YYYY-MM-DD.');
  
  const [year, month, day] = dateSQL.split('-');
  return `${day}/${month}/${year}`;
}

// Função que converte de 'dd/mm/yyyy' para 'yyyy-mm-dd'
export function formatDatePipeStringForDateSQL(dateStr: string): string {
  const [day, month, year] = dateStr.split('/');
  return `${year}-${month}-${day}`;
}
