export enum StorageKeys {
  KEY_COMPANY = 'selectedCompany',
  KEY_COMPANY_BRANCHES = 'selectedCompanyBranches',
  KEY_PERIOD = 'selectedPeriod',
  KEY_PASSWORD_RESTORE_DATA = 'restorePasswordData',
  KEY_THEME = 'selectedTheme',
  KEY_LOGGED_USER = 'loggedUser',
  KEY_TOKEN = 'token',
  KEY_REFRESH_TOKEN = 'refreshToken',
  KEY_EXPIRATION_TOKEN = 'expirationToken',
  KEY_LAST_COMPANY = 'lastCompany',
  KEY_LAST_PERIOD = 'lastPeriod',
  KEY_LAST_COMPANY_BRANCHES = 'lastCompanyBranches'
}
