import { HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { ToastService } from "src/app/data/services/components/toast/toast.service";
import { LogService } from "src/app/data/services/erp/log/log.service";
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum";
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum";
import { Toast } from "src/app/domain/interfaces/components/toast.interface";
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model";

export class LogAccountingEntryManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200 || response.body.error) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_ACCOUNTING_ENTRY:
          this.generateLogSaveAccountingEntry(request, response)
        break
        case LogFunction.DELETE_ACCOUNTING_ENTRY: 
          this.generateLogAccountingEntry(request, response, auxiliaryData.data)
        break
      }

    } catch(error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveAccountingEntry(request: HttpRequest<any>, response: HttpResponse<any>) {
    this.logService.create(
      LogModule.ACCOUNTING,
      LogScreen.ACCOUNTING_ENTRY,
      request.urlWithParams,
      request.body.editarLcto ? LogAction.EDIT : LogAction.CREATE,
      response.body.data.lancamentoId,
      null,
      `O lançamento (${response.body.data.lancamentoId}) foi ${request.body.editarLcto ? 'editado' : 'criado'}`,
      request.body
    )
  }
  
  private generateLogAccountingEntry(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any) {
    auxiliaryData.AccountingEntryIds.forEach((accountingEntryId: number) => {
      this.logService.create(
        LogModule.ACCOUNTING,
        LogScreen.ACCOUNTING_ENTRY,
        request.urlWithParams,
        LogAction.DELETE,
        accountingEntryId,
        null,
        `O lançamento (${accountingEntryId}) foi excluído`,
        request.body
      )
    })
  }
}