import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CheckFieldPipe } from './pipes/check-field.pipe'
import { ColorFactorPipe } from './pipes/color-factor/color-factor.pipe'
import { FileToBase64Pipe } from './pipes/image/file-to-base64.pipe'
import { MapInTemplatePipe } from './pipes/map/map-in-template.pipe'
import { MatchInTemplatePipe } from './pipes/match/match-in-template.pipe'
import { PrettyJsonPipe } from './pipes/pretty-json/pretty-json.pipe'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PrettyJsonPipe,
    CheckFieldPipe,
    FileToBase64Pipe,
    MapInTemplatePipe,
    MatchInTemplatePipe,
    ColorFactorPipe
  ],
  exports: [
    PrettyJsonPipe,
    CheckFieldPipe,
    FileToBase64Pipe,
    MapInTemplatePipe,
    MatchInTemplatePipe,
    ColorFactorPipe
  ]
})
export class DomainModule { }
