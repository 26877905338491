
export class Company {
  nomeFantasia: string
  id: number
  cnpj: string
  altoVolume: boolean
  filiais: Array<any>
  matrizId: number
  segmentoObrigatorio: boolean

  constructor(option?: Company) {
    this.nomeFantasia = option?.nomeFantasia ?? ''
    this.id = option?.id ?? 0
    this.cnpj = option?.cnpj ?? ''
    this.altoVolume = option?.altoVolume ?? false
    this.filiais = option?.filiais ?? new Array()
    this.matrizId = option?.matrizId ?? 0
    this.segmentoObrigatorio = option?.segmentoObrigatorio ?? false
  }
}
