import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfirmDialog } from 'src/app/domain/interfaces/components/confirm-dialog.interface'

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  public confirmSubject: Subject<ConfirmDialog> = new Subject()

  public sendConfirm(confirm: ConfirmDialog): void {
    this.confirmSubject.next(confirm)
  }
}
