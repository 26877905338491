import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { Keepalive } from '@ng-idle/keepalive'
import { EMPTY, Observable, catchError, map, tap } from 'rxjs'
import { ThemeService } from 'src/app/data/services/application/theme/theme.service'
import { UserService } from 'src/app/data/services/application/user/user.service'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { Theme } from 'src/app/domain/enums/application/theme.enum'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { ApiResponse } from 'src/app/domain/interfaces/application/api-response.interface'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { calculateMinutesDifference } from 'src/app/domain/utils/functions/calculate'
import { Md5Hash } from '../../domain/utils/classes/md5-hash'
import { AuthRepositoryService } from './auth-repository.service'
import { TokenService } from './token/token.service'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authRepositoryService: AuthRepositoryService = inject(AuthRepositoryService)
  private themeService: ThemeService = inject(ThemeService)
  private toastService: ToastService = inject(ToastService)
  private idle: Idle = inject(Idle)
  private keepalive: Keepalive = inject(Keepalive)
  private tokenService: TokenService = inject(TokenService)
  private userService: UserService = inject(UserService)

  hashPassword: Md5Hash = new Md5Hash()
  idleState = 'NOT_STARTED'

  timeToInactiveUser = 1800
  
  timeToInactiveUserDEV = 7200
  timetoGetNewToken = 5
  timeToClearSessionUser = 180
  timeToCheckTokenUser = 60

  public auth(user: string, password: string): Observable<HttpResponse<ApiResponse>> {
    const payload: any = {
      mobile: false,
      email: user,
      senha: this.hashPassword.b64_md5(password)
    }

    return this.authRepositoryService.auth(payload).pipe(
      map((res: any) => {
        const data: any = res.body.data

        this.tokenService.setToken(data.accessToken)
        this.tokenService.setRefreshToken(data.refreshToken)
        this.tokenService.setExpirationToken(data.expirationToken.toString())
        this.userService.setAuthUser(data.userInformation)
        this.themeService.setTheme(Theme.DEFAULT)
        
        return data
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: httpError.error.message } as Toast)
        return EMPTY
      })
    )
  }

  public refreshToken(): void {
    const refreshToken = this.tokenService.getRefreshToken()

    this.authRepositoryService.refreshToken(refreshToken)
    .subscribe(res =>  {
      const data = res.body.data
      this.tokenService.setToken(data.accessToken)
      this.tokenService.setRefreshToken(data.refreshToken)
      this.tokenService.setExpirationToken(data.expirationToken.toString())
    })
  }

  public sendResetPasswordCode(userMail: string): Observable<HttpResponse<ApiResponse>> {
    return this.authRepositoryService.sendPasswordResetCode(userMail).pipe(
      tap((res: HttpResponse<ApiResponse>) => {
        return res
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: httpError.error.message } as Toast)
        return EMPTY
      })
    )
  }

  public verifyResetPasswordCode(userMail: string, code: string): Observable<HttpResponse<ApiResponse>> {
    const payload = { 
      email: userMail,
      codigo: code
    }

    return this.authRepositoryService.verifyPasswordResetCode(payload).pipe(
      tap((res: HttpResponse<ApiResponse>) => {
        return res
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: httpError.error.message } as Toast)
        return EMPTY
      })
    )
  }

  public resetPassword(userToken: string, userMail: string, newPassword: string): Observable<HttpResponse<any>> {
    const payload = { 
      application: null,
      crowdId: null,
      login: userMail,
      md5Password: this.hashPassword.b64_md5(newPassword)
    }

    return this.authRepositoryService.resetPassword(payload, userToken).pipe(
      tap((res: HttpResponse<any>) => {
        return res
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao atualizar senha!' } as Toast)
        return EMPTY
      })
    )
  }

  public startIddle(): void {
    this.idle.setIdle((window.location.hostname === 'localhost') ? this.timeToInactiveUserDEV : this.timeToInactiveUser)
    this.idle.setTimeout(this.timeToClearSessionUser)
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE'
    })

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE'
      this.resetIddleVerification()
    })

    this.idle.onTimeout.subscribe(() => {
      this.userService.logout()
      this.idleState = 'TIMED_OUT'
      this.resetIddleVerification()
    })

    this.keepalive.interval(this.timeToCheckTokenUser)
    
    this.keepalive.onPing.subscribe(() => {
      if (this.tokenService.hasToken() && this.checkExpirationToken()) this.refreshToken()
    })

    this.resetIddleVerification()
  }

  private resetIddleVerification() {
    this.idle.watch()
    this.idleState = 'NOT_IDLE'
  }

  private checkExpirationToken(): boolean {
    const diff = calculateMinutesDifference(new Date(),  this.tokenService.getExpirationToken())

    return (diff <= this.timetoGetNewToken)
  }
}
