import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogEditLedgerManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if(response.status !== 200|| response.body.error) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.EDIT_LEDGER:
          this.generateLogEditLedger(request, response)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogEditLedger(request: HttpRequest<any>, response: HttpResponse<any>): void {
    request.body.livrosFiscais.forEach((editedLedger: any) => {
      this.logService.create(
        LogModule.FISCAL,
        LogScreen.TAX_BOOK_EDIT,
        request.urlWithParams,
        LogAction.EDIT,
        editedLedger.nfeId,
        editedLedger.tipoId.toString(),
        `O registro fiscal (${editedLedger.id}) foi editado`,
        request.body
      )
    })
  }
}
