import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogAccountsPlanManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if(response.status !== 200) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.CREATE_ACCOUNTS_PLAN:
          this.generateLogSaveAccountsPlan(request, response, LogAction.CREATE)
        break
        case LogFunction.EDIT_ACCOUNTS_PLAN:
          this.generateLogSaveAccountsPlan(request, response, LogAction.EDIT)
        break
        case LogFunction.DELETE_ACCOUNTS_PLAN:
          this.generateLogDeleteAccountsPlan(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  public generateLogSaveAccountsPlan(request: HttpRequest<any>, response: HttpResponse<any>, logAction: LogAction): void {
    if (!response.body[0].error) {
      let accountsPlanData = response.body.dadosPlanosContas ? response.body.dadosPlanosContas : response.body[0].dadosPlanosContas

      accountsPlanData.forEach((accountsPlanData: any) => {
        const messageLog = `O plano de contas (${accountsPlanData.codigo}) foi ${logAction === LogAction.CREATE ? 'criado' : 'editado'}${request.body.empresasGrupo && request.body.empresasGrupo.length ? ' no grupo de empresas' :  ''}`

        this.logService.create(
          LogModule.REGISTRATION,
          LogScreen.ACCOUNTS_PLAN,
          request.urlWithParams,
          logAction,
          accountsPlanData.id,
          accountsPlanData.codigo,
          messageLog,
          request.body,
          accountsPlanData.empresaId
        )
      })
    }
  }

  public generateLogDeleteAccountsPlan(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (!response.body.error) {
      auxiliaryData.idsList.forEach((accountsPlanId: number) => {
        this.logService.create(
          LogModule.REGISTRATION,
          LogScreen.ACCOUNTS_PLAN,
          request.urlWithParams,
          LogAction.DELETE,
          accountsPlanId,
          null,
          `O plano de contas (${accountsPlanId}) foi excluído`,
          request.body
        )
      })
    }
  }
}
