import { Component, OnInit, inject } from '@angular/core'
import { MessageTapeService } from 'src/app/data/services/components/message-tape/message-tape.service'
import { TypeEnvironment } from 'src/app/domain/enums/application/environment.enum'
import { Icon, IconColor } from 'src/app/domain/enums/components/icon.enum'
import { TypeMessageTape } from 'src/app/domain/enums/components/message-tape.enum'
import { MessageTapeConfig } from 'src/app/domain/interfaces/components/message-tape.interface'

@Component({
  selector: 'app-message-tape',
  templateUrl: './message-tape.component.html',
  styleUrls: ['./message-tape.component.scss']
})
export class MessageTapeComponent implements OnInit{
  private messageTapeService: MessageTapeService = inject(MessageTapeService)

  isShow: boolean = false
  message: string = ''
  type: TypeMessageTape = TypeMessageTape.DEFAULT

  icon = Icon
  iconColor = IconColor
  typeMessageType = TypeMessageTape
  
  public ngOnInit(): void {
    this.messageTapeService.getMessageTapeConfig()
    setInterval(() => this.messageTapeService.getMessageTapeConfig(), 3600000) 

    this.messageTapeService.messageTapeSubject.subscribe((messageTape: MessageTapeConfig) => this.setActiveMessageTape(messageTape))
  }

  public setActiveMessageTape(messageTapeConfig: MessageTapeConfig): void {
    this.isShow = ((window.location.hostname === 'localhost') || (messageTapeConfig.environment === TypeEnvironment.HOMOLOGATION) || ((messageTapeConfig.environment === TypeEnvironment.PRODUCTION) && messageTapeConfig.active))
    this.message = messageTapeConfig.message
    this.type = this.setTypeTape(messageTapeConfig)
  }
 
  private setTypeTape(messageTapeConfig: MessageTapeConfig): TypeMessageTape {
    if (window.location.hostname === 'localhost') return TypeMessageTape.DEVELOPMENT_ENVIRONMENT

    if (messageTapeConfig.environment === TypeEnvironment.HOMOLOGATION) return TypeMessageTape.HOMOLOGATION_ENVIRONMENT

    if ((messageTapeConfig.environment === TypeEnvironment.PRODUCTION) && messageTapeConfig.active) return TypeMessageTape.PUBLICATION_INSTABILITY

    return TypeMessageTape.DEFAULT
  }
}
