export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link'
}

export enum ButtonColor {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  INFO = 'info',
  NEUTRAL_LIGHT = 'neutral-light',
  NEUTRAL_DARK = 'neutral-dark'
}

export enum ButtonScale {
  NORMAL = 'normal',
  SMALL = 'small',
  EXTRA_SMALL = 'extra-small',
  LARGE = 'large'
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right'
}