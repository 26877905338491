import { Component, Input } from '@angular/core'
import { ButtonColor, ButtonIconPosition, ButtonScale, ButtonVariant } from 'src/app/domain/enums/components/button.enum'
import { PrimeIcon } from 'src/app/domain/enums/components/prime-icons.enum'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() label: string = ''
  @Input() icon: PrimeIcon = PrimeIcon.DEFAULT
  @Input() variant: ButtonVariant = ButtonVariant.PRIMARY
  @Input() color: ButtonColor = ButtonColor.PRIMARY
  @Input() scale: ButtonScale = ButtonScale.NORMAL
  @Input() iconPosition: ButtonIconPosition = ButtonIconPosition.LEFT
  @Input() hasIcon: boolean = true
  @Input() hasPadding: boolean = true
  @Input() disabled: boolean = false
  @Input() loading: boolean = false
  @Input() hasLabelOverflow: boolean = false
  @Input() isFullSize: boolean = false
  @Input() callback: Function = () => {}

  buttonColor = ButtonColor

  get scaleClass(): string {
    return `scale-${this.scale}`
  }
}
