import { Component, Input } from '@angular/core'
import { TooltipEvent, TooltipPosition } from 'src/app/domain/enums/components/tooltip.enum'

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() label: string = '' 
  @Input() position: TooltipPosition = TooltipPosition.BOTTOM
  @Input() event: TooltipEvent = TooltipEvent.HOVER
}
