import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode
} from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { CompanyService } from 'src/app/data/services/application/company/company.service'
import { UserService } from 'src/app/data/services/application/user/user.service'
import { TokenService } from '../auth/token/token.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private tokenService: TokenService = inject(TokenService)
  private companyService: CompanyService = inject(CompanyService)
  private userService: UserService = inject(UserService)

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.tokenService.hasToken()) {
      const token = this.tokenService.getToken()
      const selectedCompany = this.companyService.getSelectedCompanyId().toString() ?? ''
      const selectedCompanyBranches = this.companyService.getSelectedCompanyBranches().toString() ?? ''
      const selectedParentCompany = this.companyService.getSelectedParentCompanyId() ? this.companyService.getSelectedParentCompanyId().toString() : ''

      const headers = request.headers
      .set('authorization', `Bearer ${token}`)
      .set('Company-Id', selectedCompany)
      .set('Parent-Company-Id', selectedParentCompany)
      .set('Company-Branches', selectedCompanyBranches)
      .set('New-System', 'true')

      request = request.clone({ headers })
    }

    return next.handle(request).pipe(
      tap({ 
        next(httpResponse) {
          return httpResponse
        },
        error: (httpError: HttpErrorResponse) => {
          if (httpError.status === HttpStatusCode.Unauthorized) this.userService.logout()
          return httpError
        },
    }))
  }
}