import { HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { LogService } from 'src/app/data/services/erp/log/log.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { LogAction, LogFunction, LogModule, LogScreen } from '../../../enums/erp/log.enum'
import { LogAuxiliaryData } from '../../../models/erp/log/log-auxiliary-data.model'

export class LogOperationManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_OPERATION_ACCOUTING_RULE:
          this.generateLogSaveAccoutingRuleOperation(request, response)
        break
        case LogFunction.EDIT_OPERATION_ACCOUTING_RULE:
          this.generateLogEditAccoutingRuleOperation(request, response)
        break
        case LogFunction.DELETE_OPERATION_ACCOUTING_RULE:
          this.generateLogDeleteAccoutingRuleOperation(request, response, auxiliaryData.data)
        break
        case LogFunction.SAVE_OPERATION_FISCAL_RULE:
          this.generateLogSaveFiscalRuleOperation(request, response)
        break
        case LogFunction.EDIT_OPERATION_FISCAL_RULE:
          this.generateLogEditFiscalRuleOperation(request, response)
        break
        case LogFunction.DELETE_OPERATION_FISCAL_RULE:
          this.generateLogDeleteFiscalRuleOperation(request, response, auxiliaryData.data)
        break
        case LogFunction.SAVE_OPERATION_RULE:
          this.generateLogSaveRuleOperation(request, response)
        break
        case LogFunction.EDIT_OPERATION_RULE:
          this.generateLogEditRuleOperation(request, response)
        break
        case LogFunction.DELETE_OPERATION_RULE:
          this.generateLogDeleteRuleOperation(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveAccoutingRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_ACCOUTING_RULE,
        request.urlWithParams,
        LogAction.CREATE,
        response.body.operacaoId,
        request.body.descricao,
        `A operação regra contábil ${request.body.descricao} foi criada`,
        request.body
      )
    }
  }

  private generateLogEditAccoutingRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_ACCOUTING_RULE,
        request.urlWithParams,
        LogAction.EDIT,
        request.body.id,
        request.body.descricao,
        `A operação regra contábil ${request.body.descricao} foi editada`,
        request.body
      )
    }
  }

  private generateLogDeleteAccoutingRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_ACCOUTING_RULE,
        request.urlWithParams,
        LogAction.DELETE,
        auxiliaryData.accountingRule.id,
        auxiliaryData.accountingRule.descricao,
        `A operação regra contábil ${auxiliaryData.accountingRule.descricao} foi excluída`,
        request.body
      )
    }
  }

  private generateLogSaveFiscalRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_FISCAL_RULE,
        request.urlWithParams,
        LogAction.CREATE,
        response.body.operacaoId,
        request.body.codigo,
        `A operação regra fiscal ${request.body.titulo} foi criada`,
        request.body
      )
    }
  }

  private generateLogEditFiscalRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_FISCAL_RULE,
        request.urlWithParams,
        LogAction.EDIT,
        request.body.id,
        request.body.codigo,
        `A operação regra fiscal ${request.body.titulo} foi editada`,
        request.body
      )
    }
  }

  private generateLogDeleteFiscalRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status === 204) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_FISCAL_RULE,
        request.urlWithParams,
        LogAction.DELETE,
        auxiliaryData.fiscalRule.id,
        auxiliaryData.fiscalRule.codigo,
        `A operação regra fiscal ${auxiliaryData.fiscalRule.titulo} foi excluída`,
        request.body
      )
    }
  }

  private generateLogSaveRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_RULE,
        request.urlWithParams,
        LogAction.CREATE,
        response.body.operacaoId,
        request.body.cod,
        `A operação regra ${request.body.descricao} foi criada`,
        request.body
      )
    }
  }

  private generateLogEditRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.status === 200) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_RULE,
        request.urlWithParams,
        LogAction.EDIT,
        request.body.id,
        request.body.cod,
        `A operação regra ${request.body.descricao} foi editada`,
        request.body
      )
    }
  }

  private generateLogDeleteRuleOperation(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status === 204) {
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.OPERATION_RULE,
        request.urlWithParams,
        LogAction.DELETE,
        auxiliaryData.rule.id,
        auxiliaryData.rule.cod,
        `A operação regra ${auxiliaryData.rule.descricao} foi excluída`,
        request.body
      )
    }
  }
}