import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogProductServiceManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if(response.status !== 200 || response.body.error) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.CREATE_PRODUCT_SERVICE:
          this.generateLogSaveProductService(request, response, LogAction.CREATE)
        break
        case LogFunction.EDIT_PRODUCT_SERVICE:
          this.generateLogSaveProductService(request, response, LogAction.EDIT)
        break
        case LogFunction.DELETE_PRODUCT_SERVICE:
          this.generateLogDeleteProductService(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  public generateLogSaveProductService(request: HttpRequest<any>, response: HttpResponse<any>, logAction: LogAction): void {
    let productServiceData = response.body.data.dadosItem

    productServiceData.forEach((productService: any) => {
      const messageLog = `O item (${productService.codigo}) foi ${logAction === LogAction.CREATE ? 'criado' : 'editado'}${request.body.empresasGrupo && request.body.empresasGrupo.length ? ' no grupo de empresas' :  ''}`

      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.PRODUCTS_AND_SERVICES,
        request.urlWithParams,
        logAction,
        productService.id,
        productService.codigo,
        messageLog,
        request.body,
        productService.empresaId
      )
    })
  }

  public generateLogDeleteProductService(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    this.logService.create(
      LogModule.REGISTRATION,
      LogScreen.PRODUCTS_AND_SERVICES,
      request.urlWithParams,
      LogAction.DELETE,
      auxiliaryData.itemId,
      null,
      `O item (${auxiliaryData.itemId}) foi excluído`,
      request.body
    )
  }
}
