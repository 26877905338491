import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { catchError, EMPTY, map, Observable } from 'rxjs'
import { FileRepositoryService } from 'src/app/data/repositories/erp/file/file-repository.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { BlobManagement } from 'src/app/domain/utils/classes/blob-management'
import { ToastService } from '../../components/toast/toast.service'

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private fileRepositoryService: FileRepositoryService = inject(FileRepositoryService)
  private toastService: ToastService = inject(ToastService)
  
  blobManagement: BlobManagement = new BlobManagement()

  public uploadFile(formData: FormData, fileName: string): Observable<any>  {
    return this.fileRepositoryService.uploadFile(formData).pipe(
      map((res: HttpResponse<any>) => {
        return res.body
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: `Houve um problema ao realizar o upload do arquivo: ${fileName}!` } as Toast)
        return EMPTY
      })
    )
  }

  public downloadAttachmentRms(attachmentId: number): Observable<any> {
    return this.fileRepositoryService.downloadAttachmentRms(attachmentId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.blobManagement.blobError(httpError)
        return EMPTY
      })
    )
  }

  public deleteAttachmentRms(attachmentId: number): Observable<any>  {
    const payload = { anexoId: attachmentId }

    return this.fileRepositoryService.deleteAttachmentRms(payload).pipe(
      map((res: HttpResponse<any>) => {
        return res.body
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: `Houve um problema ao tentar excluir o anexo!` } as Toast)
        return EMPTY
      })
    )
  }
}
