import { Injectable } from '@angular/core'
import { StorageKeys } from 'src/app/domain/enums/application/storage-keys.enum'
import { Theme } from 'src/app/domain/enums/application/theme.enum'

@Injectable({
  providedIn: 'root'
})
export class ThemeRepositoryService {
  public setSelectedTheme(selectedTheme: Theme): void {
    localStorage.setItem(StorageKeys.KEY_THEME, String(selectedTheme))
  }

  public getSelectedTheme(): Theme {
    return localStorage.getItem(StorageKeys.KEY_THEME) as Theme
  }
}
