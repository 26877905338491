import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { BrandingSize, BrandingVariant } from 'src/app/domain/enums/components/branding.enum'

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit, OnChanges {

  @Input() variant: BrandingVariant = BrandingVariant.COLORFUL
  @Input() size: BrandingSize = BrandingSize.FULL
  @Input() width: number = 0

  brandingOcta: string  = ''

  standardWidths: Array<number> = [0, 30, 140]

  public ngOnInit(): void {
    this.changeBranding()
    this.changeWidthBranding()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.changeBranding()
    this.changeWidthBranding()
  }

  private changeBranding(): void {
    let brandingUrl: string = ''

    switch(this.variant) {
      case BrandingVariant.WHITE:
        brandingUrl = `assets/images/branding/octa-white-${this.size}.png`
      break
      default:
        brandingUrl = `assets/images/branding/octa-colorful-${this.size}.png`
      break
    }

    this.brandingOcta = brandingUrl
  }

  private changeWidthBranding(): void {
    if(this.standardWidths.includes(this.width)) {
      if(this.size === BrandingSize.FULL) this.width = 140
      else this.width = 30
    }
  }
}
