import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogFiscalNoteManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200) return
    
    try {
      switch (auxiliaryData.function) {
        case LogFunction.UNDO_BOOKKEEPING:
          this.generateLogUndoBookkeeping(request, response, auxiliaryData)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogUndoBookkeeping(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    let aux: any = auxiliaryData.data
    this.logService.create(
      aux.module === "PURCHASE" ? LogModule.PURCHASES : LogModule.SALES,
      LogScreen.INVOICES,
      request.urlWithParams,
      LogAction.DELETE,
      request.body.nfeId,
      aux.document,
      `A escrituração da NF ${aux.document} foi removida`,
      request.body
    )
  }

}