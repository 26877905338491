import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  public splashSubject: Subject<boolean> = new Subject()

  public showSplashScreen(): void {
    this.splashSubject.next(true)
  }

  public hiddenSplashScreen(): void {
    this.splashSubject.next(false)
  }
}
