import { Component, OnInit, inject } from '@angular/core'
import { MessageService } from 'primeng/api'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [ MessageService ]
})
export class ToastComponent implements OnInit {

  private messageService: MessageService = inject(MessageService)
  private toastService: ToastService = inject(ToastService)

  public ngOnInit(): void {
    this.toastService.toastSubject.subscribe((toast: Toast) => {
      this.showToast(toast)
    })
  }

  private showToast(toast: Toast): void {
    switch (toast.severity) {
      case 'success':
        this.messageService.add({ severity: toast.severity, summary: 'Sucesso', detail: toast.message })
      break
      case 'error':
        this.messageService.add({ severity: toast.severity, summary: 'Erro', detail: toast.message })
      break
      case 'warn':
        this.messageService.add({ severity: toast.severity, summary: 'Atenção', detail: toast.message })
      break
      case 'info':
        this.messageService.add({ severity: toast.severity, summary: 'Aviso', detail: toast.message })
      break
    }
  }

}
