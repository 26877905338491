import { NgModule } from '@angular/core'

import { PresentationRoutingModule } from './presentation-routing.module'
import { SharedModule } from './view/shared/shared.module'

@NgModule({
  declarations: [],
  imports: [
    PresentationRoutingModule,
    SharedModule
  ],
  exports: [ SharedModule ]
})
export class PresentationModule { }
