import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { LazyLoadEvent } from 'primeng/api'
import { BehaviorSubject, EMPTY, Observable, catchError, map } from 'rxjs'
import { LogRepositoryService } from 'src/app/data/repositories/erp/log/log-repository.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { LogAction, LogModule, LogScreen } from 'src/app/domain/enums/erp/log.enum'
import { ApiResponse } from 'src/app/domain/interfaces/application/api-response.interface'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { LogAuxiliaryData } from 'src/app/domain/models/erp/log/log-auxiliary-data.model'
import getCircularReplacer from 'src/app/domain/utils/functions/get-circular-replacer'
import { CompanyService } from '../../application/company/company.service'
import { UserService } from '../../application/user/user.service'
import { ToastService } from '../../components/toast/toast.service'

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private logRepositoryService: LogRepositoryService = inject(LogRepositoryService)
  private toastService: ToastService = inject(ToastService)
  private userService: UserService = inject(UserService)
  private companyService: CompanyService = inject(CompanyService)

  auxiliaryDataSubject: BehaviorSubject<LogAuxiliaryData> = new BehaviorSubject(new LogAuxiliaryData())
  
  public create(module: LogModule, screen: LogScreen, path: string, action: LogAction, registerId: number | null = null, registerCode: string | null = null, message: string = '', extraInformation: Object | null = null, companyId: number | null = null): void {
    const payload = {
      companyId: companyId ?? this.companyService.getSelectedCompanyId(),
      userId: this.userService.getAuthUserId(),
      module: module,
      screen: screen,
      path: path,
      action: action,
      registerId: registerId,
      registerCode: registerCode,
      message: message,
      extraInformation: JSON.stringify(extraInformation, getCircularReplacer())
    }

    this.logRepositoryService.create(payload).subscribe()
  }

  public get(companyId: number, module: LogModule, screen: LogScreen, action: LogAction, lazyloadingEvent: LazyLoadEvent = {}): Observable<any> {
    const payload = {
      companyId: companyId,
      module: module,
      screen: screen,
      action: action,
      limit: lazyloadingEvent.rows,
      offset: lazyloadingEvent.first,
      sortOrder: lazyloadingEvent.sortOrder,
      sortField: lazyloadingEvent.sortField,
      filters: lazyloadingEvent.filters
    }

    return this.logRepositoryService.get(payload).pipe(
      map((res: HttpResponse<ApiResponse>) => {
        return res.body?.data
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar LOG!' } as Toast)
        return EMPTY
      })
    )
  }

  public saveAuxiliaryData(logData: Partial<LogAuxiliaryData>): void {
    this.auxiliaryDataSubject.next(new LogAuxiliaryData(logData))
  }
}
