import { Injectable, inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Theme } from 'src/app/domain/enums/application/theme.enum'
import { ThemeRepositoryService } from '../../../repositories/application/theme/theme-repository.service'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themeRepositoryService: ThemeRepositoryService = inject(ThemeRepositoryService)

  themeSubject: BehaviorSubject<Theme> = new BehaviorSubject(this.getTheme())

  public getTheme(): Theme {
    return this.themeRepositoryService.getSelectedTheme()
  }

  public setTheme(selectedTheme: Theme): void {
    this.themeRepositoryService.setSelectedTheme(selectedTheme)
    this.themeSubject.next(selectedTheme)
  }
}
