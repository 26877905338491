import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { DataModule } from './data/data.module'
import { DomainModule } from './domain/domain.module'
import { InfraModule } from './infra/infra.module'
import { PresentationModule } from './presentation/presentation.module'

registerLocaleData(localePt)

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataModule,
    InfraModule,
    DomainModule,
    PresentationModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE,  useValue: 'BRL' },
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
