import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { PowerBIEmbedModule } from 'powerbi-client-angular'
import { CodeInputModule } from './code-input/code-input.module'

import { DomainModule } from 'src/app/domain/domain.module'
import { ComponentsModule } from './components/components.module'


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    CodeInputModule,
    PowerBIEmbedModule,
    DomainModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    CodeInputModule,
    PowerBIEmbedModule,
    DomainModule
  ],
})
export class SharedModule { }
