import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"
import { ProductionOrder } from "src/app/domain/models/erp/stock/production-order.model"

export class LogProductionOrderManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200) return
    
    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_PRODUCTION_ORDER:
          this.generateLogSaveProductionOrder(request, response)
        break
        case LogFunction.DELETE_PRODUCTION_ORDER:
          this.generateLogDeleteProductionOrder(request, response, auxiliaryData.data)
        break
        case LogFunction.REVERSE_PRODUCTION_ORDER:
          this.generateLogReverseProductionOrder(request, response, auxiliaryData.data)
        break
        case LogFunction.REPROCESS_PRODUCTION_ORDER:
          this.generateLogReprocessProductionOrder(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveProductionOrder(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if (response.body.error) return

    this.logService.create(
      LogModule.STOCK,
      LogScreen.PRODUCTION_ORDER,
      request.urlWithParams,
      LogAction.CREATE,
      response.body.data.ordemProducaoId,
      response.body.data.ordemProducaoDocumento,
      `A ordem de produção (${response.body.data.ordemProducaoDocumento}) foi criada`,
      request.body
    )
  }

  private generateLogDeleteProductionOrder(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    auxiliaryData.selectedProductionOrders.forEach((productionOrder: ProductionOrder) => {
      this.logService.create(
        LogModule.STOCK,
        LogScreen.PRODUCTION_ORDER,
        request.urlWithParams,
        LogAction.DELETE,
        productionOrder.requisicaoId,
        productionOrder.documento,
        `A ordem de produção (${productionOrder.documento}) foi excluída`,
        request.body
      )
    })
  }

  private generateLogReprocessProductionOrder(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    const productionOrder: ProductionOrder = auxiliaryData.selectedProductionOrder
    
    this.logService.create(
      LogModule.STOCK,
      LogScreen.PRODUCTION_ORDER,
      request.urlWithParams,
      LogAction.EDIT,
      productionOrder.requisicaoId,
      productionOrder.documento,
      `A ordem de produção (${productionOrder.documento}) foi reprocessada`,
      request.body
    )
  }

  private generateLogReverseProductionOrder(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    const productionOrder: ProductionOrder = auxiliaryData.productionOrder

    this.logService.create(
      LogModule.STOCK,
      LogScreen.PRODUCTION_ORDER,
      request.urlWithParams,
      LogAction.CREATE,
      productionOrder.requisicaoId,
      productionOrder.documento,
      `A ordem de produção (${productionOrder.documento}) foi estornada`,
      request.body
    )
  }

}