import { ParticipantDocumentType } from "../../enums/erp/participant-document-type.enum"

export function formatCpf(cpf: number | string | null): string {
  return String(cpf).replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
}

export function formatCnpj(cnpj: number | string | null): string {
  return String(cnpj).replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d)/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
}

export function validateCPF(CPF: string): boolean {
  CPF = CPF.replace(/[^\d]+/g, '')

  if (
    CPF.length !== 11 ||
    CPF === '00000000000' ||
    CPF === '11111111111' ||
    CPF === '22222222222' ||
    CPF === '33333333333' ||
    CPF === '44444444444' ||
    CPF === '55555555555' ||
    CPF === '66666666666' ||
    CPF === '77777777777' ||
    CPF === '88888888888' ||
    CPF === '99999999999'
  ) return false

  let additionalCheck = 0

  for (let i = 0; i < 9; i++) additionalCheck += parseInt(CPF.charAt(i), 10) * (10 - i)

  let reverseCheck = 11 - (additionalCheck % 11)

  if (reverseCheck === 10 || reverseCheck === 11) reverseCheck = 0

  if (reverseCheck !== parseInt(CPF.charAt(9), 10)) return false

  additionalCheck = 0

  for (let i = 0; i < 10; i++) additionalCheck += parseInt(CPF.charAt(i), 10) * (11 - i)

  reverseCheck = 11 - (additionalCheck % 11)

  if (reverseCheck === 10 || reverseCheck === 11)

  reverseCheck = 0

  if (reverseCheck !== parseInt(CPF.charAt(10), 10)) return false

  return true
}

export function validateCNPJ(CNPJ: string): boolean {
  CNPJ = CNPJ.replace(/[^\d]+/g, '')

  if (CNPJ.length !== 14) return false
  
  if (
    CNPJ === '00000000000000' ||
    CNPJ === '11111111111111' ||
    CNPJ === '22222222222222' ||
    CNPJ === '33333333333333' ||
    CNPJ === '44444444444444' ||
    CNPJ === '55555555555555' ||
    CNPJ === '66666666666666' ||
    CNPJ === '77777777777777' ||
    CNPJ === '88888888888888' ||
    CNPJ === '99999999999999'
  ) return false
  
  let documentNumbersLength = (CNPJ.length - 2)
  let documentNumbers = CNPJ.substring(0, documentNumbersLength)
  let documentDigit = CNPJ.substring(documentNumbersLength)
  let sum = 0
  let position = (documentNumbersLength - 7)

  for (let i = documentNumbersLength; i >= 1; i--) {
    sum += parseInt(documentNumbers.charAt(documentNumbersLength - i), 10) * position--
    if (position < 2) {
      position = 9
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(documentDigit.charAt(0), 10)) return false

  documentNumbersLength += 1
  documentNumbers = CNPJ.substring(0, documentNumbersLength)
  sum = 0
  position = documentNumbersLength - 7

  for (let i = documentNumbersLength; i >= 1; i--) {
    sum += parseInt(documentNumbers.charAt(documentNumbersLength - i), 10) * position--
    if (position < 2) {
      position = 9
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(documentDigit.charAt(1), 10)) return false
  
  return true
}

export function checkDocument(document: string): ParticipantDocumentType {
  const cpfRegex = /^\d{11}$/
  const cnpjRegex = /^\d{14}$/

  if (cpfRegex.test(document) && validateCPF(document)) return ParticipantDocumentType.CPF
  else if (cnpjRegex.test(document) && validateCNPJ(document)) return ParticipantDocumentType.CNPJ
  else return ParticipantDocumentType.INVALID
}

