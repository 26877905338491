import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { StorageKeys } from 'src/app/domain/enums/application/storage-keys.enum'
import { Company } from 'src/app/domain/models/application/company.model'
import { environment } from 'src/environments/environment'

const API_URL = environment.apiURL
const PYANALITICS_URL = environment.pyAnaliticsURL

@Injectable({
  providedIn: 'root'
})
export class CompanyRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)

  public setSelectedCompany(company: Company): void {
    sessionStorage.setItem(StorageKeys.KEY_COMPANY, JSON.stringify(company))
  }

  public setSelectedCompanyBranches(companyBranches: Array<number>): void {
    sessionStorage.setItem(StorageKeys.KEY_COMPANY_BRANCHES, companyBranches.toString())
  }

  public getSelectedCompany(): Company {
    return !!sessionStorage.getItem(StorageKeys.KEY_COMPANY) ? new Company(JSON.parse(sessionStorage.getItem(StorageKeys.KEY_COMPANY) as string)) : new Company()
  }

  public getSelectedCompanyBranches(): Array<number> {
    let selectedCompanyBranchesString: any

    if (sessionStorage.getItem(StorageKeys.KEY_COMPANY_BRANCHES)) selectedCompanyBranchesString = sessionStorage.getItem(StorageKeys.KEY_COMPANY_BRANCHES)?.split(',')
    else selectedCompanyBranchesString = new Array()

    let selectedCompanyBranchesNumber = selectedCompanyBranchesString.map((companyBranchId: any) => { return Number(companyBranchId) })

    return selectedCompanyBranchesNumber
  }

  public getAllUsers(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/usuario/todos-usuarios-empresa/${companyId}`, { observe: 'response' })
  }

  public getIntegrations(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/configuracoesapis?$select=codigo,ativo,empresaConta/id&$filter=empresa/id eq ${companyId}`, { observe: 'response' })
  }

  public getFinancialParameter(parentCompanyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/empresaparametrofinanceiro?$select=id,pedidoMisto,permiteAlterarMoeda,permiteMultiMoeda,visualizaPedido,visualizaProvisao,confTaxa/id,participanteObrigatorio,confTaxaPedido/id,operacaoRegraContabil/id,financeiroAntecipado,comissaoAtiva,vendedorObrigatorio,empresaContaPagamento/id,empresaContaRecebimento/id,recebimentoSomenteContaPadrao,pagamentoSomenteContaPadrao,filtroPedido&$filter=empresa/id eq ${parentCompanyId}`, { observe: 'response' })
  }

  public getAccountingParameter(parentCompanyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/empresaparametrocontabil?$select=visualizarContasAutomaticas&$filter=empresa/id eq ${parentCompanyId}`, { observe: 'response' })
  }

  public getParameter(parentCompanyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/empresa?$select=usarAprovacao,regimeTributario/id&$filter=id eq ${parentCompanyId}`, { observe: 'response' })
  }

  public getAccounts(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${PYANALITICS_URL}/cadastro/buscar-empresa-conta`, payload, { observe: 'response' })
  }

  public getDefaultAccounts(selectedCompaniesId: Array<number>): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/cadastro/contas-bancarias-padrao?empresaId=${selectedCompaniesId}`, { observe: 'response' })
  }

  public getCashFlowModel(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/financeiro/dashboard/get-mascaras/${companyId}`, { observe: 'response' })
  }

  public getGroup(companyId: number, userId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/cadastro/getgroupempresas/${companyId}-${userId}`, { observe: 'response' })
  }

  public getDefaultBankAccounts(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/cadastro/contas-bancarias-padrao?empresaId=${companyId}`, { observe: 'response' })
  }

  public getCompanyGroupPermission(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`, { observe: 'response' })
  }

  public getRegisterGroupCompanies(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`, { observe: 'response' })
  }

  public getGroupCompanies(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ${companyId}`, { observe: 'response' })
  }

  public getMandatoryApportionment(parentCompanyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/pedido/centro-de-custo-obrigatorio(${parentCompanyId})`, { observe: 'response' })
  }

  public getCertificateValidity(): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/cadastro/verificar-certificado`, { observe: 'response' })
  }

  public getCompanyGeneralParameters(companyId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/cadastro/parametros-empresa?empresaId=${companyId}`, { observe: 'response' })
  }
}
