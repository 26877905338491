<button pButton pRipple type="button" [loading]="loading" [class]="variant + ' ' + scaleClass" [label]="label" [disabled]="disabled" (click)="callback()" [icon]="icon" [iconPos]="iconPosition"
[ngClass]="{
  'no-icon' : !hasIcon, 
  'not-padding': !hasPadding,
  'full-size': isFullSize,
  'has-overflow': hasLabelOverflow,
  'color-primary': color === buttonColor.PRIMARY, 
  'color-neutral-light': color === buttonColor.NEUTRAL_LIGHT,
  'color-neutral-dark': color === buttonColor.NEUTRAL_DARK,
  'color-positive': color === buttonColor.POSITIVE, 
  'color-negative': color === buttonColor.NEGATIVE, 
  'color-warning': color === buttonColor.WARNING, 
  'color-info': color === buttonColor.INFO
}"></button>