export enum LogModule {
  ACCOUNTING = 'contabil',
  REGISTRATION = 'cadastro',
  FINANCE = 'financeiro',
  FISCAL = 'fiscal',
  PURCHASES = 'compras',
  SALES = 'vendas',
  STOCK = 'estoque',
  PARAMETER = 'parametro',
  RMS = 'requisicao'
}

export enum LogAction {
  CREATE = 'criação',
  EDIT = 'edição',
  DELETE = 'deleção'
}

export enum LogScreen {
  INVOICES = 'notas-fiscais',
  PRODUCT_PURCHASES_ORDER = 'pedido-compra-produto',
  PRODUCT_SALES_ORDER = 'pedido-venda-produto',
  SERVICE_PURCHASES_ORDER = 'pedido-compra-servico',
  SERVICE_SALES_ORDER = 'pedido-venda-servico',
  OPERATION_ACCOUTING_RULE = 'operacao-regra-contabil',
  OPERATION_FISCAL_RULE = 'operacao-regra-fiscal',
  OPERATION_RULE = 'operacao-regra',
  ACCOUNTING_ENTRY = 'lancamento',
  ACCOUNTS_PLAN = 'plano-contas',
  MOVEMENT = 'movimentacao',
  PARTICIPANTS = 'clientes-fornecedores',
  PAYMENTS = 'pagamentos',
  PRODUCTION_ORDER = 'ordem-producao',
  PRODUCTS_AND_SERVICES = 'produtos-servicos',
  RECEIPTS = 'recebimentos',
  STOCK_MOVEMENT = 'movimentacao-interna',
  TAX_BOOK_EDIT = 'edit-livro',
  PARAMETER_CENTER = 'central-parametros',
  PERIOD_PARAMETER_CENTER = 'central-de-periodo',
  RMS_SALES = 'rms-de-venda',
  RMS_PURCHASES = 'rms-de-compra',
}

export enum LogFunction {
  ACCOUNTING_REPROCESS = 'accountingReprocess',
  FINANCIAL_REPROCESS = 'financialReprocess',
  SAVE_ORDER = 'saveOrder',
  DELETE_ORDERS = 'deleteOrders',
  BOOKKEPING_ORDERS = 'bookkepingOrders',
  BATCH_EDIT_ORDERS = 'batchEditOrders',
  CLONE_ORDERS = 'cloneOrders',
  SAVE_OPERATION_ACCOUTING_RULE = 'saveOperationAccoutingRule',
  EDIT_OPERATION_ACCOUTING_RULE = 'editOperationAccoutingRule',
  DELETE_OPERATION_ACCOUTING_RULE = 'deleteOperationAccoutingRule',
  SAVE_OPERATION_FISCAL_RULE = 'saveOperationFiscalRule',
  EDIT_OPERATION_FISCAL_RULE = 'editOperationFiscalRule',
  DELETE_OPERATION_FISCAL_RULE = 'deleteOperationFiscalRule',
  SAVE_OPERATION_RULE = 'saveOperationRule',
  EDIT_OPERATION_RULE = 'editOperationRule',
  DELETE_OPERATION_RULE = 'deleteOperationRule',
  SAVE_FINANCIAL_TITLE = 'saveFinancialTitle',
  DELETE_FINANCIAL_TITLE = 'deleteFinancialTitle',
  CREATE_PARTICIPANT = 'createParticipant',
  EDIT_PARTICIPANT = 'editParticipant',
  DELETE_PARTICIPANTS = 'linkParticipants',
  LINK_PARTICIPANT = 'deleteParticipants',
  SAVE_ACCOUNTING_ENTRY = 'saveAccountingEntry',
  DELETE_ACCOUNTING_ENTRY = 'deleteAccountingEntry',
  SAVE_INTERNAL_MOVEMENT = 'saveInternalMovement',
  REPROCESS_INTERNAL_MOVEMENT = 'reprocessInternalMovement',
  DELETE_INTERNAL_MOVEMENT = 'deleteInternalMovement',
  SAVE_PRODUCTION_ORDER = 'saveProductionOrder',
  DELETE_PRODUCTION_ORDER = 'deleteProductionOrder',
  REVERSE_PRODUCTION_ORDER = 'reverseProductionOrder',
  REPROCESS_PRODUCTION_ORDER = 'reprocessProductionOrder',
  CREATE_ACCOUNTS_PLAN = 'createAccountsPlan',
  EDIT_ACCOUNTS_PLAN = 'editAccountsPlan',
  DELETE_ACCOUNTS_PLAN = 'deleteAccountsPlan',
  CREATE_PRODUCT_SERVICE = 'createProductService',
  EDIT_PRODUCT_SERVICE = 'editProductService',
  DELETE_PRODUCT_SERVICE = 'deleteProductService',
  EDIT_LEDGER = 'editLedger',
  EDIT_PRODUCT_SERVICE_PARAMETER = 'editProductServiceParameter',
  UNDO_BOOKKEEPING = 'undoBookkeeping',
  SAVE_USGAAP_ACCOUNTING_ENTRY = 'saveUsGaapAccountingEntry',
  CHANGE_DATE_TAX_PERIOD = 'changeDateTaxPeriod',
  CHANGE_TAX_PERIOD_STATUS = 'changeTaxPeriodStatus',
  RECALCULATE_ACCOUNTING_PERIOD = 'recalculateAccountingPeriodStatus',
  SAVE_ACCOUNTING_PERIOD = 'saveAccountingPeriodStatus',
  CHANGE_STOCK_PERIOD_STATUS = 'changeStockPeriodStatus',
  RECALCULATE_STOCK_PERIOD_COST = 'recalculateStockPeriodCostStatus',
  CHANGE_ACCOUNTING_PERIOD_STATUS = 'changeAccountingPeriodStatus',
  RECALCULATE_FINANCIAL_PERIOD = 'recalculateFinancialPeriod',
  CHANGE_FINANCIAL_PERIOD_STATUS = 'changeFinancialPeriodStatus',
  CHANGE_ACCOUNTS_DATES = 'chnageAccountDates',
  SAVE_RMS = 'saveRms',
  SAVE_RMS_PARAMETER = 'saveRmsParameter',
}