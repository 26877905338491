export const ExternalRouters = {
  URI: 'externo',
  URL: 'externo',

  leadCompleteInformations: {
    URI: 'completar-informacoes-lead/:uuid',
    URL: '/externo/completar-informacoes-lead/',
  },
  customerApproverBudget: {
    URI: 'aprovar-orcamento/:uuid',
    URL: '/externo/aprovar-orcamento/',
  },
  grantorCompleteQuotation: {
    URI: 'completar-cotacao/:uuid',
    URL: '/externo/completar-cotacao/',
  },
}