import { HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { LogService } from 'src/app/data/services/erp/log/log.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { LogAction, LogFunction, LogModule, LogScreen } from 'src/app/domain/enums/erp/log.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { FinancialTitleTable } from 'src/app/domain/models/erp/financial/financial-title-table.model'
import { LogAuxiliaryData } from '../../../models/erp/log/log-auxiliary-data.model'

export class LogFinancialManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_FINANCIAL_TITLE:
          this.generateLogSaveFinancialTitle(request, response)
        break
        case LogFunction.DELETE_FINANCIAL_TITLE:
          this.generateLogDeleteFinancialTitle(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveFinancialTitle(request: HttpRequest<any>, response: HttpResponse<any>): void {
    this.logService.create(
      LogModule.FINANCE,
      ((request.body.tipo === 'cp') ? LogScreen.PAYMENTS : LogScreen.RECEIPTS),
      request.urlWithParams,
      ((request.body.id === null) ? LogAction.CREATE : LogAction.EDIT),
      response.body.nfeFinId,
      request.body.historico,
      `O título ${request.body.historico} foi ${((request.body.id === null) ? 'criado' : 'editado')}`,
      request.body
    )
  }

  private generateLogDeleteFinancialTitle(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    auxiliaryData.forEach((financialTitle: FinancialTitleTable) => {
      this.logService.create(
        LogModule.FINANCE,
        ((request.body.tipo === 'cp') ? LogScreen.PAYMENTS : LogScreen.RECEIPTS),
        request.urlWithParams,
        LogAction.DELETE,
        financialTitle.id,
        financialTitle.historico,
        `O título ${financialTitle.documento || financialTitle.historico} foi excluído!`,
        request.body
      )
    })
  }
}