import { HttpClient, HttpResponse } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

const API_URL = environment.apiURL
const PYANALITICS_URL = environment.pyAnaliticsURL

@Injectable({
  providedIn: 'root'
})
export class FileRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)

  public uploadFile(formData: FormData): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${API_URL}/centralweb/custom/upload`, formData, { observe: 'response' })
  }

  public downloadAttachmentRms(attachmentId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/workflow/download-anexo?anexoId=${attachmentId}`, { observe: 'response', responseType: 'blob' })
  }

  public deleteAttachmentRms(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${PYANALITICS_URL}/workflow/excluir-anexo`, payload, { observe: 'response' })
  }
}
