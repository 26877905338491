import { HttpErrorResponse, HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogInternalMovementManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any> | HttpErrorResponse, auxiliaryData: LogAuxiliaryData): void {

    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_INTERNAL_MOVEMENT:
          this.generateLogSaveInternalMovement(request, (response as HttpResponse<any>))
        break
        case LogFunction.REPROCESS_INTERNAL_MOVEMENT:
          this.generateLogReprocessInternalMovement(request, (response as HttpResponse<any>), auxiliaryData.data)
        break
        case LogFunction.DELETE_INTERNAL_MOVEMENT:
          this.generateLogDeleteInternalMovement(request, response)
        break
      }
    } catch(error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveInternalMovement(request: HttpRequest<any>, response: HttpResponse<any>): void  {
    if (response.status !== 200 || response.body.error) return   

    this.logService.create(
      LogModule.STOCK,
      LogScreen.STOCK_MOVEMENT,
      request.urlWithParams,
      LogAction.CREATE,
      response.body.data,
      null,
      `A movimentação interna (${response.body.data}) foi criada`,
      request.body
    )
  }

  private generateLogReprocessInternalMovement(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status !== 200 || response.body.error) return   

    auxiliaryData.movementsIds.forEach((movementId: number) => {
      this.logService.create(
        LogModule.STOCK,
        LogScreen.STOCK_MOVEMENT,
        request.urlWithParams,
        LogAction.EDIT,
        movementId,
        null,
        `A movimentação interna (${movementId}) foi reprocessada`,
        request.body
      )
    })
  }

  private generateLogDeleteInternalMovement(request: HttpRequest<any>, response: HttpResponse<any> |HttpErrorResponse): void {
    const data: Array<any> = ((response instanceof HttpResponse) ? response.body.miExcluidas : response.error.miExcluidas)

    data.forEach((map: any) => {
      this.logService.create(
        LogModule.STOCK,
        LogScreen.STOCK_MOVEMENT,
        request.urlWithParams,
        LogAction.DELETE,
        map.id,
        map.doc,
        `A movimentação interna ${map.doc} foi excluída`,
        request.body
      )
    })
  }
}