export class AuthUser {
  phone: string
  email: string
  id: number
  level: number
  displayName: string
  cell: string
  isChatAdmin: boolean
  viewLog: boolean
  
  constructor(option?: AuthUser) {
    this.phone = option?.phone ?? ''
    this.email = option?.email ?? ''
    this.id = option?.id ?? 0
    this.level = option?.level ?? 1
    this.displayName = option?.displayName ?? ''
    this.cell = option?.cell ?? ''
    this.isChatAdmin = option?.isChatAdmin ?? false
    this.viewLog = option?.viewLog ?? false
  }
}