export function calculateVariationValueString(value: number = 0, lastYearValue: number = 0): string {
  let valueVariation: string = '0'

  valueVariation = ((lastYearValue !== 0) ? ((value - (lastYearValue)) / Math.abs(lastYearValue) * 100).toFixed(1) : '0')

  return valueVariation
}

export function calculateMinutesDifference(firstDate: Date, secondDate: Date): number {
  const millisecondsDifference = Math.abs(secondDate.getTime() - firstDate.getTime())
  
  const minutesDifference = Math.floor(millisecondsDifference / (1000 * 60))

  return minutesDifference
}