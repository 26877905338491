export enum TooltipPosition {
  RIGHT = 'right',
  LEFT = 'left',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum TooltipEvent {
  HOVER = 'hover',
  FOCUS = 'focus'
}