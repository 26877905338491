import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { SelectItem } from 'primeng/api'
import { EMPTY, Observable, catchError, map } from 'rxjs'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { Company } from 'src/app/domain/models/application/company.model'
import deepCopy from 'src/app/domain/utils/functions/deep-copy'
import { CompanyRepositoryService } from '../../../repositories/application/company/company-repository.service'
import { ToastService } from '../../components/toast/toast.service'
import { FileService } from '../../erp/file/file.service'

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companyRepositoryService: CompanyRepositoryService = inject(CompanyRepositoryService)
  private toastService: ToastService = inject(ToastService)
  private fileService: FileService = inject(FileService)

  public setSelectedCompany(company: Company): void {
    this.companyRepositoryService.setSelectedCompany(company)
  }

  public setSelectedCompanyBranches(companyBranches: Array<number>): void {
    this.companyRepositoryService.setSelectedCompanyBranches(companyBranches)
  }

  public getSelectedCompany(): Company {
    return this.companyRepositoryService.getSelectedCompany()
  }

  public getSelectedCompanyBranches(): Array<number> {
    return this.companyRepositoryService.getSelectedCompanyBranches()
  }

  public getSelectedCompanyId(): number {
    return this.companyRepositoryService.getSelectedCompany().id
  }

  public getSelectedParentCompanyId(): number {
    return this.companyRepositoryService.getSelectedCompany().matrizId
  }

  public getSelectedCompanyCnpj(): string {
    return String(this.companyRepositoryService.getSelectedCompany().cnpj)
  }

  public getSelectedCompanyFantasy(): string {
    return this.companyRepositoryService.getSelectedCompany().nomeFantasia
  }

  public hasSelectedCompany(): boolean {
    return !!this.companyRepositoryService.getSelectedCompany()
  }

  public getOptionsAllUsers(companyId: number, fullName: boolean = false): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getAllUsers(companyId).pipe(
      map((res: HttpResponse<any>) => {
        let allUserOptions = new Array()

        res.body?.users.forEach((user: any) => {
          allUserOptions.push({
            label: fullName ? user.nome_completo : user.alias,
            value: user.id
          })
        })

        return allUserOptions
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os usuários desta empresa!' } as Toast)
        return new Array()
      })
    )
  }

  public getIntegrations(companyId: number): Observable<any> {
    return this.companyRepositoryService.getIntegrations(companyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body.value
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha as integrações de API desta empresa!' } as Toast)
        return new Array()
      })
    )
  }

  public getFinancialParameter(companyId: number): Observable<any> {
    return this.companyRepositoryService.getFinancialParameter(companyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body.value[0]
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os parâmetros financeiros da empresa!' } as Toast)
        return EMPTY
      })
    )
  }

  public getAccountingParameter(companyId: number): Observable<any> {
    return this.companyRepositoryService.getAccountingParameter(companyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body.value[0]
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os parâmetros contabeis da empresa!' } as Toast)
        return EMPTY
      })
    )
  }

  public getParameter(parentCompanyId: number): Observable<any> {
    return this.companyRepositoryService.getParameter(parentCompanyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body.value[0]
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os parâmetros da empresa!' } as Toast)
        return EMPTY
      })
    )
  }

  public getOptionsAccount(companyList: Array<number>, isFilterTypeAccountTen?: boolean, onlyActive: boolean=true): Observable<Array<any>> {
    const payload = {
      "empresas": companyList,
      "somenteAtivas": onlyActive
    }
    return this.companyRepositoryService.getAccounts(payload).pipe(
      map((res: HttpResponse<any>) => {
        let optionsAccount: Array<any> = new Array()

        const optionsResponse: Array<any> = deepCopy(res.body?.data ?? new Array())

        optionsResponse.forEach((option: any) => {
          optionsResponse.forEach((validOption: any) => {
            if (option.instituicaoFinanceira && validOption.instituicaoFinanceira && (validOption.instituicaoFinanceira['@id'] !== undefined) && (validOption.instituicaoFinanceira['@id'] === option.instituicaoFinanceira['@ref'])) option.instituicaoFinanceira = deepCopy(validOption.instituicaoFinanceira)
          })

          const createdOption: any = {
            label: `${option.id} - ${option.nome} ${(option.agencia ? ' - AG: ' + option.agencia : '')} ${(option.conta ? ' - CC: ' + option.conta : '')} ${(option.dvConta ? '-' + option.dvConta : '')} ${(option.tipoConta ? ' - ' + option.tipoConta.descricao : '')}`,
            value: option.id,
            agency: option.agencia,
            account: option.conta,
            active: option.ativo,
            dvAccount: option.dvConta,
            financialInstitution: option.instituicaoFinanceira,
            name: option.nome,
            accountType: option.tipoConta
          }

          if (isFilterTypeAccountTen) {
            if (option.tipoConta.id !== 10) optionsAccount.push(createdOption)
          } else optionsAccount.push(createdOption)
        })

        return optionsAccount
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar as contas da empresa!' } as Toast)
        return EMPTY
      })
    )
  }

  public getOptionsDefaultAccounts(selectedCompaniesId: Array<number>): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getDefaultAccounts(selectedCompaniesId).pipe(
      map((res: HttpResponse<any>) => {
        let optionsDefaultAccount: Array<SelectItem> = new Array()

        res.body.data.forEach((option: any) => {
          const createdOption: SelectItem = {
            label: option.nome,
            value: option.id
          }

          optionsDefaultAccount.push(createdOption)
        })

        return optionsDefaultAccount
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar as contas padrões da empresa!' } as Toast)
        return EMPTY
      })
    )
  }

  public getOptionsCashFlowModel(companyId: number): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getCashFlowModel(companyId).pipe(
      map((res: HttpResponse<any>) => {
        let optionsCashFlowModel: Array<SelectItem> = new Array()

        res.body.data.forEach((option: any) => {
          const createdOption: SelectItem = {
            label: option.nome_fluxo,
            value: option.id
          }

          optionsCashFlowModel.push(createdOption)
        })

        return optionsCashFlowModel
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os modelos de fluxo de caixa da empresa!' } as Toast)
        return EMPTY
      })
    )
  }
  
  public getOptionsGroup(companyId: number, userId: number): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getGroup(companyId, userId).pipe(
      map((res: HttpResponse<any>) => {
        let optionsGroup: Array<SelectItem> = new Array()

        res.body?.data?.forEach((company: any) => optionsGroup.push({ label: company.fantasia, value: company.id  }))

        return optionsGroup
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar as empresas do grupo!' } as Toast)
        return EMPTY
      })
    )
  }

  public getDefaultBankAccounts(companyId: number): Observable<Array<any>> {
    return this.companyRepositoryService.getDefaultBankAccounts(companyId).pipe(
      map(res => res.body.data),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar tipos de conta padrão!' } as Toast)
        return EMPTY
      })
    )
  }

  public getCompanyGroupPermission(companyId: number): Observable<any> {
    return this.companyRepositoryService.getCompanyGroupPermission(companyId).pipe(
      map(res => {
        if (res.body.error) {
          this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Erro ao verificar as permissões da empresa, contate o suporte!' })
          return false
        } else {
          return res.body.permissoes
        }
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Erro ao verificar as permissões da empresa, contate o suporte!' })
        return EMPTY
      })
    )
  }

  public getRegisterGroupCompanies(companyId: number): Observable<boolean> {
    return this.companyRepositoryService.getRegisterGroupCompanies(companyId).pipe(
      map(res => {
        return res.body.value[0]?.cadastroGrupoEmpresa ? true : false
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Erro ao verificar grupos de empresa' })
        return EMPTY
      })
    )
  }

  public getGroupCompanies(companyId: number): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getGroupCompanies(companyId).pipe(
      map(res => {
        const values: Array<any> = res.body.value

        return values.map(company => ({
          label: company.empresaRelac.nomeFantasia,
          value: company.empresaRelac.id
        }))
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Erro ao recuperar grupos de empresa' })
        return EMPTY
      })
    )
  }

  public getMandatoryApportionment(parentCompanyId: number): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getMandatoryApportionment(parentCompanyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar o centro de custo obrigatório!' } as Toast)
        return EMPTY
      })
    )
  }

  public getCertificateValidity(): Observable<Array<SelectItem>> {
    return this.companyRepositoryService.getCertificateValidity().pipe(
      map((res: HttpResponse<any>) => {
        return res.body
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        return EMPTY
      })
    )
  }

  public renewCertificate(file: File, userId: number, filePassword: string): Observable<any>  {
    const payload: FormData = new FormData()

    payload.append('file', file)
    payload.append('type', 'pfx')
    payload.append('usuarioId', userId.toString())
    payload.append('pass', filePassword)

    return this.fileService.uploadFile(payload, file.name)
  }

  public getCompanyGeneralParameters(companyId: number): Observable<any> {
    return this.companyRepositoryService.getCompanyGeneralParameters(companyId).pipe(
      map((res: HttpResponse<any>) => {
        return res.body.data
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar os parâmetros da empresa!' } as Toast)
        return EMPTY
      })
    )
  }
}