import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../infra/auth/guard/auth.guard'
import { LoginGuard } from '../infra/auth/guard/login.guard'
import { ErpRoutes as erp_router } from './view/erp/erp.router'
import { NotFoundComponent } from './view/errors/not-found/not-found.component'
import { ExternalRouters as external_router } from './view/external/external.router'
import { HomeRoutes as home_router } from './view/home/home.router'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: home_router.URI,
  },
  {
    path: home_router.URI,
    loadChildren: () => import('./view/home/home.module').then((m) => m.HomeModule),
    canLoad: [ LoginGuard ]
  },
  {
    path: erp_router.URI,
    loadChildren: () => import('./view/erp/erp.module').then((m) => m.ErpModule),
    canLoad: [ AuthGuard ]
  },
  {
    path: external_router.URI,
    loadChildren: () => import('./view/external/external.module').then((m) => m.ExternalModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PresentationRoutingModule {}
