import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map, tap } from 'rxjs'
import { LogManagement } from 'src/app/domain/utils/classes/log/log-management'

@Injectable()
export class LogInterceptor implements HttpInterceptor {

  private logManagement: LogManagement = new LogManagement()

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((response: any) => {
        if (response.ok && request.urlWithParams.indexOf('/log/gravar-log-modulo') === -1) this.logManagement.generateLog(request, response)
        return response
      }),
      tap({ 
        next(httpResponse) {
          return httpResponse
        },
        error: (httpError: HttpErrorResponse) => {
          this.logManagement.generateLog(request, httpError)
          return httpError
        },
    }))
  }
}
