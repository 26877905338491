import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { StorageKeys } from 'src/app/domain/enums/application/storage-keys.enum'
import { AuthUser } from 'src/app/domain/models/application/auth-user.model'
import { environment } from 'src/environments/environment'

const API_URL = environment.apiURL
const PYANALITICS_URL = environment.pyAnaliticsURL
const IPIFY_URL = 'https://api.ipify.org/?format=json'

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)
  public handler: HttpBackend = inject(HttpBackend)

  private httpClientExternal: HttpClient = new HttpClient(this.handler)

  public setAuthUser(authUser: AuthUser): void {
    localStorage.setItem(StorageKeys.KEY_LOGGED_USER, JSON.stringify(authUser))
  }

  public getAuthUser(): AuthUser {
    return !!localStorage.getItem(StorageKeys.KEY_LOGGED_USER) ? new AuthUser(JSON.parse(localStorage.getItem(StorageKeys.KEY_LOGGED_USER) as string)) : new AuthUser()
  }

  public getCompanies(userId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/cadastro/get-usuario-empresas?auth_user_id=${userId}`, { observe: 'response' })
  }

  public getIPAddress(): Observable<HttpResponse<any>> {
    return this.httpClientExternal.get(`${IPIFY_URL}`, { observe: 'response' })
  }

  public saveNetPromoterScore(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${PYANALITICS_URL}/log/salvar-nps`, payload, { observe: 'response' })
  }


  public getIsShowNetPromoterScore(userId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${PYANALITICS_URL}/log/mostrar-nps-usuario?userId=${userId}`, { observe: 'response' })
  }
}
