import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

const PYANALITICS_URL = environment.pyAnaliticsURL

@Injectable({
  providedIn: 'root'
})
export class LogRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)
  
  public create(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${PYANALITICS_URL}/log/gravar-log-modulo`, payload, { observe: 'response' })
  }

  public get(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${PYANALITICS_URL}/log/buscar-log-modulo`, payload, { observe: 'response' })
  }
}
