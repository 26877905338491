import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiResponse } from 'src/app/domain/interfaces/application/api-response.interface'
import { environment } from 'src/environments/environment'

const API_URL = environment.apiURL

@Injectable({
  providedIn: 'root'
})
export class AuthRepositoryService {

  private httpClient: HttpClient = inject(HttpClient)

  public auth(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${API_URL}/centralweb/custom/autenticacao/login`, payload, { observe: 'response' })
  }

  public refreshToken(refreshToken: string): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${API_URL}/centralweb/custom/autenticacao/atualizar-token`, { refreshToken: refreshToken }, { observe: 'response' })
  }

  public sendPasswordResetCode(userMail: string): Observable<HttpResponse<ApiResponse>> {
    return this.httpClient.get(`${API_URL}/centralweb/custom/usuario/email-recuperacao-senha(${userMail})`, { observe: 'response' })
  }

  public verifyPasswordResetCode(payload: Object): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${API_URL}/centralweb/custom/usuario/verifica-codigo-recuperacao-senha`, payload, { observe: 'response' })
  }

  public resetPassword(payload: Object, userToken: string): Observable<HttpResponse<any>> {
    return this.httpClient.post(`${API_URL}/centralweb/custom/usuario/reset-password(${userToken})`, payload, { observe: 'response' })
  }
}
