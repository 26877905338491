import { Injectable, inject } from '@angular/core'
import { TokenRepositoryService } from './token-repository.service'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenRepositoryService: TokenRepositoryService = inject(TokenRepositoryService)

  public setToken(token: string): void {
    this.tokenRepositoryService.setToken(token)
  }

  public setRefreshToken(refreshToken: string): void {
    this.tokenRepositoryService.setRefreshToken(refreshToken)
  }

  public setExpirationToken(expirationToken: string): void {
    this.tokenRepositoryService.setExpirationToken(expirationToken)
  }

  public getToken(): string {
    return this.tokenRepositoryService.getToken()
  }

  public getRefreshToken(): string {
    return this.tokenRepositoryService.getRefreshToken()
  }

  public getExpirationToken(): Date {
    return this.tokenRepositoryService.getExpirationToken()
  }
  
  public hasToken(): boolean {
    return !!this.getToken()
  }
}