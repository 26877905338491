export default function getCircusdlarReplacer(): any {
  const ancestors: Array<any> = []
  
  return function (this: any, key: any, value: any) {
    if (typeof value !== "object" || value === null) return value

    while (ancestors.length > 0 && ancestors.at(-1) !== this) ancestors.pop()

    if (ancestors.includes(value)) return '[Circular]'
    
    ancestors.push(value)

    return value
  }
}