import { LogFunction } from 'src/app/domain/enums/erp/log.enum'

export class LogAuxiliaryData {
  function: LogFunction | null
  data: any

  constructor(option?: Partial<LogAuxiliaryData>) {
    this.function = option?.function ?? null
    this.data = option?.data ?? null
  }
}