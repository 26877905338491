import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PrimengModule } from '../primeng/primeng.module'

import { IConfig, NgxMaskModule } from 'ngx-mask'
import { DomainModule } from "src/app/domain/domain.module"
import { HighchartsModule } from '../highcharts/highcharts.module'
import { AccountsPlanSelectorComponent } from './accounts-plan-selector/accounts-plan-selector.component'
import { ApportionmentSelectorComponent } from './apportionment-selector/apportionment-selector.component'
import { ApprovalSidebarContentComponent } from './approval-sidebar/approval-sidebar-content/approval-sidebar-content.component'
import { ApprovalSidebarComponent } from './approval-sidebar/approval-sidebar.component'
import { AvatarComponent } from './avatar/avatar.component'
import { BlockedLayerComponent } from './blocked-layer/blocked-layer.component'
import { BrandingComponent } from './branding/branding.component'
import { ButtonComponent } from './button/button.component'
import { CalendarComponent } from './calendar/calendar.component'
import { ChartComponent } from './chart/chart.component'
import { CheckboxComponent } from './checkbox/checkbox.component'
import { ChipPercentualVariationComponent } from './chip-percentual-variation/chip-percentual-variation.component'
import { ChipComponent } from './chip/chip.component'
import { ColorPickerComponent } from './color-picker/color-picker.component'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { ContextMenuComponent } from './context-menu/context-menu.component'
import { DashCardComponent } from './dash-card/dash-card.component'
import { DataViewComponent } from './data-view/data-view.component'
import { DialogGeneralHelpComponent } from './dialog-general-help/dialog-general-help.component'
import { DialogComponent } from './dialog/dialog.component'
import { DropdownComponent } from './dropdown/dropdown.component'
import { EditableTableComponent } from './editable-table/editable-table.component'
import { EditorComponent } from './editor/editor.component'
import { ExplanationComponent } from './explanation/explanation.component'
import { FullScreenComponent } from './full-screen/full-screen.component'
import { HeaderComponent } from './header/header.component'
import { IconComponent } from './icon/icon.component'
import { ImageComponent } from './image/image.component'
import { InfoCardComponent } from './info-card/info-card.component'
import { InputChipsComponent } from './input-chips/input-chips.component'
import { InputMaskComponent } from './input-mask/input-mask.component'
import { InputNumberComponent } from './input-number/input-number.component'
import { InputPasswordComponent } from './input-password/input-password.component'
import { InputTextComponent } from './input-text/input-text.component'
import { ItemSelectorComponent } from './item-selector/item-selector.component'
import { LoadingLayerComponent } from './loading-layer/loading-layer.component'
import { MessageTapeComponent } from './message-tape/message-tape.component'
import { MultiselectComponent } from './multiselect/multiselect.component'
import { NetPromoterScoreComponent } from './net-promoter-score/net-promoter-score.component'
import { NotificationComponent } from './notification/notification.component'
import { OfflineScreenComponent } from './offline-screen/offline-screen.component'
import { OrderContractInstallmentTableComponent } from './order-contract-installment-table/order-contract-installment-table.component'
import { OrderFormComponent } from './order-form/order-form.component'
import { OverlayPanelComponent } from './overlay-panel/overlay-panel.component'
import { PageComponent } from './page/page.component'
import { PaginatorComponent } from './paginator/paginator.component'
import { PanelComponent } from './panel/panel.component'
import { ParticipantSelectorComponent } from './participant-selector/participant-selector.component'
import { PaymentInformationSelectorComponent } from './payment-information-selector/payment-information-selector.component'
import { PicklistComponent } from './picklist/picklist.component'
import { QuotationSidebarContentComponent } from './quotation-sidebar/quotation-sidebar-content/quotation-sidebar-content.component'
import { QuotationSidebarComponent } from './quotation-sidebar/quotation-sidebar.component'
import { RadioComponent } from './radio/radio.component'
import { ReportActionCardComponent } from './report-action-card/report-action-card.component'
import { SidenavLinkComponent } from './sidenav/sidenav-link/sidenav-link.component'
import { SidenavMainButtonComponent } from './sidenav/sidenav-main-button/sidenav-main-button.component'
import { SidenavComponent } from './sidenav/sidenav.component'
import { SplashScreenComponent } from './splash-screen/splash-screen.component'
import { StockAddressingModalComponent } from './stock-addressing-modal/stock-addressing-modal.component'
import { TabChipComponent } from './tab-chip/tab-chip.component'
import { TabViewComponent } from './tab-view/tab-view.component'
import { TableComponent } from './table/table.component'
import { TagSelectorComponent } from './tag-selector/tag-selector.component'
import { TextAreaComponent } from './text-area/text-area.component'
import { TieredMenuComponent } from './tiered-menu/tiered-menu.component'
import { ToastComponent } from './toast/toast.component'
import { ToggleComponent } from './toggle/toggle.component'
import { ToolbarComponent } from './toolbar/toolbar.component'
import { TooltipComponent } from './tooltip/tooltip.component'
import { TreeTableComponent } from './tree-table/tree-table.component'
import { TreeComponent } from './tree/tree.component'
import { UploadComponent } from './upload/upload.component';
import { AssistantAiCardGroupComponent } from './assistant-ai-card-group/assistant-ai-card-group.component'
import { AiHistoryActionCardComponent } from './ai-history-action-card/ai-history-action-card.component'
import { InfiniteScrollListComponent } from './infinite-scroll-list/infinite-scroll-list.component'


export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null

@NgModule({
  declarations: [
    SidenavComponent,
    InputTextComponent,
    ButtonComponent,
    BrandingComponent,
    ToastComponent,
    HeaderComponent,
    IconComponent,
    DropdownComponent,
    AvatarComponent,
    TieredMenuComponent,
    OverlayPanelComponent,
    TooltipComponent,
    PageComponent,
    TableComponent,
    ToolbarComponent,
    CheckboxComponent,
    ConfirmDialogComponent,
    DialogComponent,
    DialogComponent,
    CalendarComponent,
    SplashScreenComponent,
    ChipComponent,
    EditorComponent,
    UploadComponent,
    RadioComponent,
    TextAreaComponent,
    InputPasswordComponent,
    InputNumberComponent,
    InputMaskComponent,
    LoadingLayerComponent,
    ToggleComponent,
    MultiselectComponent,
    ApprovalSidebarComponent,
    ApprovalSidebarContentComponent,
    PanelComponent,
    SidenavMainButtonComponent,
    SidenavLinkComponent,
    ParticipantSelectorComponent,
    DataViewComponent,
    BlockedLayerComponent,
    PaymentInformationSelectorComponent,
    InfoCardComponent,
    TabViewComponent,
    ApportionmentSelectorComponent,
    ItemSelectorComponent,
    ChartComponent,
    DashCardComponent,
    PaginatorComponent,
    ChipPercentualVariationComponent,
    DialogGeneralHelpComponent,
    AccountsPlanSelectorComponent,
    MessageTapeComponent,
    EditableTableComponent,
    NotificationComponent,
    OfflineScreenComponent,
    ReportActionCardComponent,
    FullScreenComponent,
    TabChipComponent,
    PicklistComponent,
    NetPromoterScoreComponent,
    TreeComponent,
    ImageComponent,
    OrderFormComponent,
    QuotationSidebarComponent,
    QuotationSidebarContentComponent,
    TreeTableComponent,
    InputChipsComponent,
    StockAddressingModalComponent,
    OrderContractInstallmentTableComponent,
    ExplanationComponent,
    AssistantAiCardGroupComponent,
    AiHistoryActionCardComponent,
    InfiniteScrollListComponent,
    ContextMenuComponent,
    TagSelectorComponent,
    ColorPickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    HighchartsModule,
    NgxMaskModule.forRoot(),
    DomainModule
  ],
  exports: [
    SidenavComponent,
    InputTextComponent,
    ButtonComponent,
    BrandingComponent,
    ToastComponent,
    HeaderComponent,
    IconComponent,
    DropdownComponent,
    AvatarComponent,
    TieredMenuComponent,
    TooltipComponent,
    PageComponent,
    TableComponent,
    ToolbarComponent,
    CheckboxComponent,
    ConfirmDialogComponent,
    DialogComponent,
    CalendarComponent,
    SplashScreenComponent,
    ChipComponent,
    EditorComponent,
    UploadComponent,
    RadioComponent,
    TextAreaComponent,
    InputPasswordComponent,
    InputNumberComponent,
    InputMaskComponent,
    LoadingLayerComponent,
    ToggleComponent,
    MultiselectComponent,
    ApprovalSidebarComponent,
    PanelComponent,
    ParticipantSelectorComponent,
    BlockedLayerComponent,
    PaymentInformationSelectorComponent,
    InfoCardComponent,
    TabViewComponent,
    ApportionmentSelectorComponent,
    ItemSelectorComponent,
    ChartComponent,
    DashCardComponent,
    PaginatorComponent,
    ChipPercentualVariationComponent,
    DialogGeneralHelpComponent,
    AccountsPlanSelectorComponent,
    MessageTapeComponent,
    EditableTableComponent,
    OfflineScreenComponent,
    ReportActionCardComponent,
    FullScreenComponent,
    TabChipComponent,
    PicklistComponent,
    NetPromoterScoreComponent,
    TreeComponent,
    ImageComponent,
    OrderFormComponent,
    QuotationSidebarComponent,
    TreeTableComponent,
    InputChipsComponent,
    StockAddressingModalComponent,
    OrderContractInstallmentTableComponent,
    ExplanationComponent,
    AssistantAiCardGroupComponent,
    AiHistoryActionCardComponent,
    InfiniteScrollListComponent,
    ContextMenuComponent,
    TagSelectorComponent,
    ColorPickerComponent
  ],
})
export class ComponentsModule {
}
