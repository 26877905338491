import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { ToastSeverity } from '../../enums/components/toast.enum'
import { Toast } from '../../interfaces/components/toast.interface'

export class BlobManagement {

  private toastService: ToastService = inject(ToastService)

  public blobError(httpErrorBlob: HttpErrorResponse): void {
    const fileBlob = new Blob([httpErrorBlob.error], {type: 'application/json'})
    const fileReader = new FileReader()

    fileReader.readAsText(fileBlob)

    fileReader.addEventListener('loadend', (fileProcessed: ProgressEvent<FileReader>) => 
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: JSON.parse(fileProcessed.target?.result as string)?.message ?? 'Falha ao realizar download do arquivo!' } as Toast)
    )
  }

  public blobDownload(blob: Blob, fileName: string): void {
    const link = document.createElement('a')

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)

      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      link.style.visibility = 'hidden'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}