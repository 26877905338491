import { Injectable } from '@angular/core'
import { StorageKeys } from 'src/app/domain/enums/application/storage-keys.enum'



@Injectable({
  providedIn: 'root'
})
export class TokenRepositoryService {

  public setToken(token: string): void {
    localStorage.setItem(StorageKeys.KEY_TOKEN, token)
  }

  public setRefreshToken(refreshToken: string): void {
    localStorage.setItem(StorageKeys.KEY_REFRESH_TOKEN, refreshToken)
  }

  public setExpirationToken(expirationToken: string): void {
    localStorage.setItem(StorageKeys.KEY_EXPIRATION_TOKEN, expirationToken)
  }

  public getToken(): string {
    return (localStorage.getItem(StorageKeys.KEY_TOKEN) ?? '')
  }

  public getRefreshToken(): string {
    return (localStorage.getItem(StorageKeys.KEY_REFRESH_TOKEN) ?? '')
  }

  public getExpirationToken(): Date {
    return new Date(Number(localStorage.getItem(StorageKeys.KEY_EXPIRATION_TOKEN) ?? ''))
  }
}
