import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { SelectItem } from 'primeng/api'
import { Observable, Subscriber, catchError, map } from 'rxjs'
import { StorageKeys } from 'src/app/domain/enums/application/storage-keys.enum'
import { CloseMethodNetPromoterScore } from 'src/app/domain/enums/components/net-promoter-score.enum'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { ApiResponse } from 'src/app/domain/interfaces/application/api-response.interface'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { AuthUser } from 'src/app/domain/models/application/auth-user.model'
import { Company } from 'src/app/domain/models/application/company.model'
import { formatCnpj } from 'src/app/domain/utils/functions/format-document'
import { TokenService } from 'src/app/infra/auth/token/token.service'
import { UserRepositoryService } from '../../../repositories/application/user/user-repository.service'
import { SplashScreenService } from '../../components/splash-screen/splash-screen.service'
import { ToastService } from '../../components/toast/toast.service'
import { SocketService } from '../socket/socket.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userRepositoryService: UserRepositoryService = inject(UserRepositoryService)
  private splashScreenService: SplashScreenService = inject(SplashScreenService)
  private tokenService: TokenService = inject(TokenService)
  private socketService: SocketService = inject(SocketService)
  private router: Router = inject(Router)
  private toastService: ToastService = inject(ToastService)

  public setAuthUser(authUser: AuthUser): void {
    this.userRepositoryService.setAuthUser(authUser)
  }

  public getAuthUserId(): number {
    return this.userRepositoryService.getAuthUser().id
  }

  public getAuthUserDisplayName(): string {
    return this.userRepositoryService.getAuthUser().displayName
  }

  public getAuthUserEmail(): string {
    return this.userRepositoryService.getAuthUser().email
  }

  public logout(): void {
    this.splashScreenService.hiddenSplashScreen()
    this.router.navigate(['/'])
    this.socketService.disconnect()
    this.logoutClearStorage()
  }

  private logoutClearStorage(): void {
    localStorage.removeItem('ng2Idle.main.expiry')
    localStorage.removeItem(StorageKeys.KEY_THEME)
    localStorage.removeItem(StorageKeys.KEY_LOGGED_USER)
    localStorage.removeItem(StorageKeys.KEY_REFRESH_TOKEN)
    localStorage.removeItem(StorageKeys.KEY_EXPIRATION_TOKEN)
    localStorage.removeItem(StorageKeys.KEY_TOKEN)
    sessionStorage.removeItem(StorageKeys.KEY_COMPANY)
    sessionStorage.removeItem(StorageKeys.KEY_COMPANY_BRANCHES)
  }

  public isLogged(): boolean {
    return this.tokenService.hasToken()
  }

  public getOptionsCompany(): Observable<Array<any>> {
    return this.userRepositoryService.getCompanies(this.getAuthUserId()).pipe(
      map((res: HttpResponse<any>) => {
        let companyOptions: Array<any> = new Array()

        res.body?.value[0]?.empresas?.forEach((company: Company) => {
          const companyBranchesOptions: Array<SelectItem> = company.filiais.map((branch: any) => {
            return { label: `${formatCnpj(branch.cnpj)} - ${branch.nomeFantasia}`, value: branch.id }
          })

          companyOptions.push({
            label: `${formatCnpj(company.cnpj)} - ${company.nomeFantasia}`,
            value: company.id,
            nationalRegister: String(company.cnpj),
            fantasyName: company.nomeFantasia,
            branches: companyBranchesOptions,
            highVolume: company.altoVolume,
            parentId: company.matrizId,
            mandatorySegment: company.segmentoObrigatorio
          })
        })

        return companyOptions
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar suas empresas disponíveis!' } as Toast)
        return new Array()
      })
    )
  }

  public getIPAddress(): Observable<Array<any>> {
    return this.userRepositoryService.getIPAddress().pipe(
      map((res: HttpResponse<any>) => res.body.ip),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Falha ao recuperar o enderço de IP do usuário!' } as Toast)
        return new Array()
      })
    )
  }

  public getGeolocation(): Observable<GeolocationPosition> {
    return new Observable<GeolocationPosition>((subscriber: Subscriber<GeolocationPosition>) => {
      navigator.geolocation.watchPosition(
        GeolocationPosition => {
          subscriber.next(GeolocationPosition)
          subscriber.complete()
        },
        GeolocationPositionError => subscriber.error(GeolocationPositionError)
      )
    })
  }

  public saveNetPromoterScore(companyId: number, userId: number, score: number | null, feedback: string, browser: string, closeMethod: CloseMethodNetPromoterScore): void {
    const payload = {
      companyId: companyId,
      userId: userId,
      score: score,
      feedback: feedback,
      browser: browser,
      closeMethodId: closeMethod
    }

    this.userRepositoryService.saveNetPromoterScore(payload).pipe().subscribe()
  }

  public getIsShowNetPromoterScore(userId: number): Observable<boolean> {
    return this.userRepositoryService.getIsShowNetPromoterScore(userId).pipe(
      map((res: HttpResponse<ApiResponse>) => (res.body?.data ?? false))
    )
  }
}
