import { HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { ToastService } from "src/app/data/services/components/toast/toast.service";
import { LogService } from "src/app/data/services/erp/log/log.service";
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum";
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum";
import { Toast } from "src/app/domain/interfaces/components/toast.interface";
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model";

export class LogParticipantManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200 || response.body.error) return

    try {
      switch(auxiliaryData.function) {
        case LogFunction.CREATE_PARTICIPANT:
          this.generateLogSaveParticipant(request, response, LogAction.CREATE, auxiliaryData.function)
        break
        case LogFunction.EDIT_PARTICIPANT:
          this.generateLogSaveParticipant(request, response, LogAction.EDIT, auxiliaryData.function)
        break
        case LogFunction.LINK_PARTICIPANT:
          this.generateLogSaveParticipant(request, response, LogAction.CREATE, auxiliaryData.function)
        break
        case LogFunction.DELETE_PARTICIPANTS:
          this.generateLogDeleteParticipant(request, response, auxiliaryData)
        break
      }
    } catch(error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogSaveParticipant(request: HttpRequest<any>, response: HttpResponse<any>, logAction: LogAction, logFunction: LogFunction): void {
    const participantData = response.body.data.dadosParticipante
    
    participantData.forEach((participant: any) => {
      const messageLog = this.setLogMessage(request, logFunction, participant)

      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.PARTICIPANTS,
        request.urlWithParams,
        logAction,
        participant.id,
        participant.codigo,
        messageLog,
        request.body,
        participant.empresaId
      )
    })
  }

  private generateLogDeleteParticipant(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    auxiliaryData.data.selectedParticipants.forEach((participant: any) => {
      const messageLog = this.setLogMessage(request, auxiliaryData.function!, participant)
      
      this.logService.create(
        LogModule.REGISTRATION,
        LogScreen.PARTICIPANTS,
        request.urlWithParams,
        LogAction.DELETE,
        participant.participanteId,
        participant.codigo,
        messageLog,
        request.body
      )
    })
  }

  private setLogMessage(request: HttpRequest<any>, logFunction: LogFunction, participant: any): string {
    let logMessage: string = ''

    switch(logFunction) {
      case LogFunction.CREATE_PARTICIPANT:
        logMessage = `O participante (${participant.codigo}) foi criado ${request.body.empresasGrupo && request.body.empresasGrupo.length ? ' no grupo de empresas' :  ''}`
      break
      case LogFunction.EDIT_PARTICIPANT:
        logMessage = `O participante (${participant.codigo}) foi editado ${request.body.empresasGrupo && request.body.empresasGrupo.length ? ' no grupo de empresas' :  ''}`
      break
      case LogFunction.DELETE_PARTICIPANTS:
        logMessage = `O participante (${participant.codigo}) foi excluído`
      break
      case LogFunction.LINK_PARTICIPANT:
        logMessage = `O participante (${participant.codigo}) foi vinculado ${request.body.empresasGrupo && request.body.empresasGrupo.length ? ' no grupo de empresas' :  ''}`
      break
    }

    return logMessage
  }
}