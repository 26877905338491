export const HomeRoutes = {
  URI: 'home',
  URL: 'home',
  signin: {
    URI: '',
    URL: 'home',
  },
  restorePassword: {
    URI: 'recuperar-senha',
    URL: '/home/recuperar-senha',
  },
  verifyCode: {
    URI: 'validar-codigo',
    URL: '/home/validar-codigo',
  },
  newPassword: {
    URI: 'nova-senha',
    URL: '/home/nova-senha'
  }
}