import { HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { MessageTapeRepositoryService } from 'src/app/data/repositories/components/message-tape/message-tape-repository.service'
import { TypeEnvironment } from 'src/app/domain/enums/application/environment.enum'
import { MessageTapeConfig } from 'src/app/domain/interfaces/components/message-tape.interface'
import { environment } from 'src/environments/environment'
import { UserService } from '../../application/user/user.service'

const TYPE_ENVIRONMENT = environment.type

@Injectable({
  providedIn: 'root'
})
export class MessageTapeService {

  private messageTapeRepositoryService: MessageTapeRepositoryService = inject(MessageTapeRepositoryService)
  private userService: UserService = inject(UserService)

  messageTapeSubject: BehaviorSubject<MessageTapeConfig> = new BehaviorSubject({message: '', active: false, environment: TypeEnvironment.DEVELOPER} as MessageTapeConfig)

  selectedEnvironment: TypeEnvironment = TYPE_ENVIRONMENT

  public getMessageTapeConfig(): void {
    if (this.userService.isLogged()) this.messageTapeRepositoryService.getPublicationNotice().subscribe((res: HttpResponse<any>) => {
      this.messageTapeSubject.next({
        message: res.body.value[0].mensagem,
        active: res.body.value[0].ativo,
        environment: this.selectedEnvironment
      })
    })
    else this.messageTapeSubject.next({
      message: '',
      active: false,
      environment: this.selectedEnvironment
    })
  }
}
