import { HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { ToastService } from "src/app/data/services/components/toast/toast.service";
import { LogService } from "src/app/data/services/erp/log/log.service";
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum";
import { InOutType } from "src/app/domain/enums/erp/in-out-type.enum";
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum";
import { Toast } from "src/app/domain/interfaces/components/toast.interface";
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model";

export class LogReprocessManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.ACCOUNTING_REPROCESS:
          this.generateLogAccountingReprocess(request, response, auxiliaryData.data)
          break

        case LogFunction.FINANCIAL_REPROCESS:
          this.generateLogFinancialReprocess(request, response, auxiliaryData.data)
          break
      }

    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogAccountingReprocess(request: HttpRequest<any>, response: HttpResponse<any>, data: any) {
    data.auxiliaryData.selectedEntries.forEach((entry: any) => {
      this.logService.create(
        this.setModule(data.auxiliaryData),
        this.setScreen(data.auxiliaryData),
        request.urlWithParams,
        LogAction.EDIT,
        this.setId(data.auxiliaryData, entry),
        this.setDescription(data.auxiliaryData, entry),
        this.setMessage(data.auxiliaryData, entry),
        request.body
      )
    })
  }

  private generateLogFinancialReprocess(request: HttpRequest<any>, response: HttpResponse<any>, data: any) {
    data.auxiliaryData.selectedEntries.forEach((entry: any) => {
      this.logService.create(
        this.setModule(data.auxiliaryData),
        LogScreen.INVOICES,
        request.urlWithParams,
        LogAction.EDIT,
        this.setId(data.auxiliaryData, entry),
        this.setDescription(data.auxiliaryData, entry),
        `O financeiro da nota fiscal (${entry.numNf}) foi reprocessado`,
        request.body
      )
    })
  }

  private setModule(data: any) {
    if (data.type === 'nf' && data.inOutType === InOutType.IN) return LogModule.PURCHASES
    else if (data.type === 'nf' && data.inOutType === InOutType.OUT) return LogModule.SALES
    else return LogModule.FINANCE
  }

  private setScreen(data: any) {
    if (data.type === 'baixa' && data.inOutType === InOutType.IN) return LogScreen.RECEIPTS
    else if (data.type === 'baixa' && data.inOutType === InOutType.OUT) return LogScreen.PAYMENTS
    else return LogScreen.INVOICES
  }

  private setId(data: any, entry: any) {
    if (data.type === 'nf' && data.inOutType === InOutType.IN) return entry.nfeId
    else if (data.type === 'nf' && data.inOutType === InOutType.OUT) return entry.nfe_id
    else return entry.id
  }

  private setDescription(data: any, entry: any) {
    if (data.type === 'nf' && data.inOutType === InOutType.IN) return entry.numNf
    else if (data.type === 'nf' && data.inOutType === InOutType.OUT) return entry.document
    else return entry.historico
  }

  private setMessage(data: any, entry: any) {
    if (data.type === 'nf' && data.inOutType === InOutType.IN) return  `A contabilidade da nota fiscal (${entry.numNf}) foi reprocessada`
    else if (data.type === 'nf' && data.inOutType === InOutType.OUT) return `A contabilidade da nota fiscal (${entry.document}) foi reprocessada`
    else return `A contabilidade da baixa do título (${entry.historico}) foi reprocessada`
  }
}