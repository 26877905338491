import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { EMPTY, Observable, Subject, catchError, map } from 'rxjs'
import { ServerHealthRepositoryService } from 'src/app/data/repositories/application/server-health/server-health-repository.service'
import { ServerHealth } from 'src/app/domain/enums/application/server-health.enum'
import { environment } from 'src/environments/environment'

const API_SOCKET_URL = environment.apiSocketURL

@Injectable({
  providedIn: 'root'
})
export class ServerHealthService {

  private serverHealthRepositoryService: ServerHealthRepositoryService = inject(ServerHealthRepositoryService)

  private apiHealthTimeout: any = null
  private apiWebSocket: WebSocket | null = null
  apiHealthSubject: Subject<ServerHealth> = new Subject()

  public startMonitoringAPIHealth(): void {
    this.connectAPIHealth()
  }
  
  private getAPIHealth(): void {
    this.serverHealthRepositoryService.getAPIHealth().pipe(
      map((res: HttpResponse<any>) => {
        this.apiHealthSubject.next(ServerHealth.ONLINE)
      }),
      catchError((httpError: HttpErrorResponse, caught: Observable<any>) => {
        this.apiHealthSubject.next(ServerHealth.OFFLINE)
        this.handleAPIHealth()
        return EMPTY
      })
    ).subscribe()
  }

  private connectAPIHealth(): void {
    this.apiWebSocket = new WebSocket(`${API_SOCKET_URL}/proof/socket/main`)

    this.apiWebSocket.onclose = () => {
      this.apiHealthSubject.next(ServerHealth.OFFLINE)
      this.handleAPIHealth()
    }
  }

  private handleAPIHealth(): void {
    clearTimeout(this.apiHealthTimeout)
    this.apiHealthTimeout = setTimeout(() => this.getAPIHealth(), 5000)
  }
}
